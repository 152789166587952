import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { CredentialsService } from '@app/core';
import { environment } from '@env/environment';
import { LoadingController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  corporateZones: any = [];
  sendGeofences = new Subject<boolean>();
  sendGeofences$ = this.sendGeofences.asObservable();
  corporategeo: any[] = [];
  numbers: any[] = [];
  circles: Array<any> = [];
  users: Array<any> = [];
  devicesType: any[] = [];
  isVideoEnabled: boolean = true;

  tmpUsers: any[] = [];
  tmpCircles: any[] = [];

  collective: any = {};
  collectives: any = [];
  crudUsers: boolean = false;

  search: boolean = false;
  search_timeout: any;

  /* switches */
  traffic: boolean = false;

  group: string = null;
  getAll: boolean = false;

  constructor(
    public afd: AngularFireDatabase,
    public afs: AngularFirestore,
    public credentialsService: CredentialsService,
    private loadingController: LoadingController
  ) {
    this.getDeviceTypes();

    this.group =
      environment.GROUP != ''
        ? environment.GROUP
        : this.credentialsService.credentials.group != ''
        ? this.credentialsService.credentials.group
        : null;
    this.getAll = this.group == null ? true : false;
  }

  getCorporateZones(corporate: string) {
    const cor = corporate != 'risk' ? corporate : 'risk';
    const route = cor == 'risk' ? `risk` : `geofences/${cor}/geofences`;

    this.afs
      .collection(route)
      .snapshotChanges()
      .pipe(
        map(posts =>
          posts.map((post: any) => {
            const geo: any = post.payload.doc.data();
            geo.id = post.payload.doc.id;
            geo.origin = 'firebase';

            return geo;
          })
        )
      )
      .subscribe(val => {
        this.corporategeo = val;
        this.sendGeofences.next();
      });
  }

  getDeviceTypes() {
    this.afs
      .collection(`devicesType`)
      .snapshotChanges()
      .pipe(
        take(1),
        map(posts =>
          posts.map((post: any) => {
            const type: any = post.payload.doc.data();
            type.id = post.payload.doc.id;

            return type;
          })
        )
      )
      .subscribe(val => {
        this.devicesType = val;
      });
  }

  async getNumbers() {
    this.afs
      .collection(`/third-party/diatel/records`)
      .snapshotChanges()
      .subscribe(data => {
        let tmp: unknown[] = [];
        data.forEach(phone => {
          const element = phone.payload.doc.data();
          tmp.push(element);
        });
        this.numbers = tmp;
      });
  }

  async getCircles() {
    const loading = await this.loadingController.create({
      message: 'Obteniendo grupos...',
      spinner: 'bubbles'
    });
    await loading.present();

    let tmp: unknown[] = [];
    let circlesref = null;

    if (this.getAll) {
      circlesref = this.afs.collection(`circles`, ref => ref.where('test', '!=', true));
    } else {
      circlesref = this.afs.collection('circles', ref => ref.where('group', '==', this.group));
    }

    circlesref
      .stateChanges()
      .pipe(take(1))
      .subscribe(data => {
        console.log('loading fs circles', data.length);
        data.forEach(phone => {
          const element: any = phone.payload.doc.data();
          element['id'] = phone.payload.doc.id;

          if (!element['deletedInfo']) {
            const idx = tmp.findIndex((x: any) => {
              return element['id'] == x['id'];
            });

            if (idx < 0) {
              tmp.push(element);
            }
          }
        });

        if (this.getAll) {
          this.afd
            .list(`circles` /* , ref => ref.limitToFirst(10) */)
            .snapshotChanges()
            .pipe(take(1))
            .subscribe(data => {
              console.log('loading rt circles', data.length);

              data.forEach(phone => {
                let element: any = phone.payload.val();

                if (!element['test'] && !element['deleted']) {
                  element['id'] = phone.key;

                  const idx = tmp.findIndex((x: any) => {
                    return element['id'] == x['id'];
                  });
                  if (idx < 0) {
                    element['alias'] = element['id'];
                    tmp.push(element);
                  }
                }
              });
              this.circles = tmp;
              loading.dismiss();
            });
        } else {
          this.circles = tmp;
          loading.dismiss();
        }
      });
  }

  async getUsers() {
    try {
      const loading = await this.loadingController.create({
        message: 'Obteniendo usuarios...',
        spinner: 'bubbles'
      });
      await loading.present();

      let tmp: unknown[] = [];
      let usersref = null;
      console.log(this.getAll);

      if (this.getAll) {
        usersref = this.afs.collection(`users`, ref => ref.where('test', '!=', true));

        usersref
          .stateChanges()
          .pipe(take(1))
          .subscribe(fsusers => {
            console.log('loading fs users', fsusers.length);

            fsusers.forEach(user => {
              const element: any = user.payload.doc.data();
              element['id'] = user.payload.doc.id;

              const idx = tmp.findIndex((x: any) => {
                return element['id'] == x['id'];
              });

              if (idx < 0) {
                tmp.push(element);
              }
            });

            this.afd
              .list(`users`)
              .snapshotChanges()
              .pipe(take(1))
              .subscribe(rtusers => {
                console.log('loading rt users', rtusers.length);

                rtusers.forEach(user => {
                  const element: any = user.payload.val();

                  if (!element['test'] && !element['deleted']) {
                    element['id'] = user.key;

                    const idx = tmp.findIndex((x: any) => {
                      return element['id'] == x['id'];
                    });
                    if (idx < 0) {
                      tmp.push(element);
                    }
                  }
                });

                this.users = tmp;
                loading.dismiss();
              });
          });
      } else {
        this.users = tmp;
        loading.dismiss();
      }
    } catch (error) {
      console.error(error);
    }
  }

  getUserItems(ev: any) {
    try {
      const val: string = ev.target.value;
      if (val == '') {
        this.tmpUsers = this.users;
        this.search = false;
        return;
      }

      this.search = true;

      if (this.search_timeout) {
        clearTimeout(this.search_timeout);
      }

      this.search_timeout = setTimeout(async () => {
        let tmpUsers: any[] = [];

        if (this.getAll) {
          /* not group */
          this.users.forEach(user => {
            switch (true) {
              case user.username && user.username.search(val.toUpperCase()) !== -1:
              case user.cid && user.cid.search(val.toUpperCase()) !== -1:
              case user.email && user.email.search(val.toLowerCase()) !== -1:
                tmpUsers.push(user);

                break;

              default:
                break;
            }
          });
        } else {
          /* only groups */
          const us = await this.afs
            .collection('users', ref =>
              ref
                .where('username', '>=', `${val.toUpperCase()}`)
                .where('username', '<', `${val.toUpperCase()}`)
                .orderBy('username', 'asc')
            )
            .get()
            .toPromise();

          if (us.size > 0) {
            us.docs.forEach(u => {
              u.data().circles.forEach((circle: string) => {
                const idx = this.circles.findIndex(cir => {
                  return cir.id == circle;
                });

                if (idx > -1) {
                  let element = u.data();
                  element.id = u.id;
                  element.key = u.id;

                  tmpUsers.push(element);

                  return;
                }
              });
            });
          }
        }

        this.tmpUsers = tmpUsers;
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  }

  getCircleItems(ev: any) {
    const val: string = ev.target.value;
    if (val == '') {
      this.tmpCircles = this.circles;
      this.search = false;
      return;
    }

    this.search = true;

    if (this.search_timeout) {
      clearTimeout(this.search_timeout);
    }

    this.search_timeout = setTimeout(async () => {
      let tmpCircles: any[] = [];
      this.circles.forEach(circle => {
        switch (true) {
          case circle.alias && circle.alias.search(val.toUpperCase()) !== -1:
            tmpCircles.push(circle);

            break;

          default:
            break;
        }
      });

      this.tmpCircles = tmpCircles;
    }, 2000);
  }

  async circleNames(circles: Array<string>) {
    let names = [];
    for (let index = 0; index < circles.length; index++) {
      const element = circles[index];
      const circle = await this.singleCircleName(element);
      names.push({ alias: circle.alias, id: element, group: circle.group });
    }

    return names;
  }

  async singleCircleName(id: string) {
    let search = false;
    let name = id;
    let group = null;

    if (this.circles.length < 1) {
      search = true;
    } else {
      const idx = this.circles.findIndex(x => {
        return id == x['id'];
      });

      if (idx < 0) {
        search = true;
      } else {
        name = this.circles[idx].alias;
        group = this.circles[idx].group;
      }
    }

    if (search) {
      const cirRef = await this.afs
        .doc(`circles/${id}`)
        .get()
        .toPromise();

      if (cirRef.exists) {
        name = cirRef.data().alias ? cirRef.data().alias : id;
        group = cirRef.data().group ? cirRef.data().group : null;
      }
    }

    return { alias: name, group: group };
  }

  async getGroup(circles: Array<any>) {
    //let collectives = [];
    let groups = [
      ...new Set(
        circles
          .filter(function(obj) {
            return obj.group;
          })
          .map(function(obj) {
            return obj.group;
          })
      )
    ];

    /* for (let index = 0; index < groups.length; index++) {
      const element = groups[index];
      collectives.push((await this.getCollectiveName(element)).name);
    } */

    return groups;
  }

  async getCollectiveNames(groups: Array<string>) {
    if (!groups) return null;

    let collectives = [];
    for (let index = 0; index < groups.length; index++) {
      const element = groups[index];
      collectives.push((await this.getCollectiveName(element)).name);
    }

    return collectives.length > 0 ? collectives : null;
  }

  async getCollectiveName(id: string) {
    let search = false;
    let name = id;

    try {
      if (this.collectives.length < 1) {
        search = true;
      } else {
        const idx = this.collectives.findIndex((x: { [x: string]: string }) => {
          return id == x['id'];
        });

        if (idx < 0) {
          search = true;
        } else {
          name = this.collectives[idx].name;
          id = this.collectives[idx].id;
        }
      }

      if (search) {
        const collRef = await this.afs
          .doc(`group/${id}`)
          .get()
          .toPromise();

        if (collRef.exists) {
          name = collRef.data().name ? collRef.data().name : id;
          id = collRef.data().id ? collRef.data().id : id;
        }
      }
    } catch (error) {
      console.error('cannot get collectivename', error);
    }
    return { name: name, id: id };
  }

  async videoEnable(corporate: string) {
    this.isVideoEnabled = true;
    this.afs
      .doc(`group/${corporate}`)
      .snapshotChanges()
      .subscribe(val => {
        if (val.payload.exists) {
          let data: any = val.payload.data();
          this.isVideoEnabled = data['videoCall'] ? Boolean(data['videoCall']) : this.isVideoEnabled;
        }
      });
  }

  async getCollective(collective: string) {
    const collectiveRef = await this.afs
      .doc(`group/${collective}`)
      .get()
      .toPromise();

    if (collectiveRef.exists) {
      this.collective = collectiveRef.data();
      this.collective['id'] = collectiveRef.id;
    }

    if ('crudUsers' in this.collective) {
      this.crudUsers = Boolean(this.collective.crudUsers);
    }

    return this.collective;
  }

  setMembershipParsed(membership: string) {
    let parsed = '';

    switch (membership) {
      case 'black':
        parsed = 'Black';
        break;
      case 'plus':
        parsed = 'zafe';
        break;
      default:
        parsed = 'Tecnotrust viejo';
    }

    return parsed;
  }

  random() {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}

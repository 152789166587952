import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, Platform, AlertController } from '@ionic/angular';
import { forkJoin, from } from 'rxjs';

import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService, untilDestroyed, CredentialsService } from '@app/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/auth';
import * as firebase from 'firebase';
import { map } from 'rxjs/operators';

const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() initSubscribe = true;
  isLoading = false;
  typeUser = 1;
  providerSocial: string;

  mailPass = true;
  recoveryPass = false;

  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  appSchema = '';
  app = 'com.enacment.tecnotrust';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private platform: Platform,
    private loadingController: LoadingController,
    private i18nService: I18nService,
    private authenticationService: AuthenticationService,
    private afAuth: AngularFireAuth,
    private credentialsService: CredentialsService,
    private db: AngularFireDatabase,
    private afs: AngularFirestore,
    public alertController: AlertController
  ) {}

  ngOnInit() {
    /* console.log(this.platform.platforms())
    if (!this.platform.is('mobileweb')) {
      this.appAlert();
    } */

    firebase.auth().languageCode = 'es';
    if (this.initSubscribe) {
      this.authenticationService.subscribeAuth();
    }
  }

  ngOnDestroy() {}

  /*   async getApp() {
    this.appAvailability.check(this.app).then(
      async (yes: boolean) => {
        const ret = await App.openUrl({
          url: this.app
        });
      },
      async (no: boolean) => {
        try {
          NativeMarket.openStoreListing({
            appId: this.app
          });
        } catch (error) {
          console.error('not found', error);
        }
      }
    );
  } */

  async login(type: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    const loading$ = from(loadingOverlay.present());

    try {
      switch (type) {
        case 'google':
          this.googleSign();
          break;
        case 'email':
          this.emailAlert();
          break;
      }
    } catch (error) {}

    this.isLoading = false;
    loadingOverlay.dismiss();
  }

  async googleSign(): Promise<firebase.auth.UserCredential> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
    /* const googleUser = await firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD.signIn();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const credential = firebase.auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
    this.typeUser = 2; */

    const provider = new firebase.auth.GoogleAuthProvider();

    if (this.initSubscribe) {
      return this.afAuth.auth.signInWithPopup(provider);
    }
    /* else {
         const setLogin = await (await this.afAuth.auth.currentUser).linkWithCredential(credential);
         this.subscribeAuth();
         return setLogin;
       } */
  }

  async emailSign(email: string, pwd: string) {
    return await this.authenticationService.loginEmail(email, pwd).catch(error => {
      console.error(error);

      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;

      switch (errorCode) {
        case 'auth/invalid-email':
          errorMessage = 'El correo electrónico ingresado no tiene el formato correcto.';
          break;
        case 'auth/user-disabled':
          errorMessage = 'El usuario relacionado a este correo fue inhabilitado.';
          break;
        case 'auth/user-not-found':
          errorMessage =
            'El correo electrónico que ingresaste no corresponde a un usuario ' + environment.APP_NAME + '.';
          break;
        case 'auth/wrong-password':
          errorMessage = 'El password que ingresaste es incorrecto, por favor intenta nuevamente.';
          break;
        case 'auth/network-request-failed':
          errorMessage = 'Error de conexión, asegúrate de estar conectado a un punto WiFi o datos móviles.';
          break;
        default:
          errorMessage = error.message;
          break;
      }

      this.showAlert('Error al iniciar sesión', errorMessage);
    });
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'No autorizado',
      message: 'No se encuentra autorizado para entrar, solicite un acceso.',
      buttons: ['OK']
    });
    this.authenticationService.logout();
    await alert.present();
  }

  async emailAlert() {
    const alert = await this.alertController.create({
      header: 'Ingresa tus datos',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Correo electrónico'
        },
        {
          name: 'pwd',
          type: 'password',
          placeholder: 'Contraseña'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Iniciar',
          handler: (data: any) => {
            this.emailSign(data.email, data.pwd);
          }
        }
      ]
    });
    await alert.present();
  }

  async showAlert(title: string, message: string) {
    let alert = await this.alertController.create({
      header: title,
      subHeader: message,
      buttons: ['OK']
    });
    alert.present();
    setTimeout(() => {
      alert.dismiss();
    }, 5000);
  }

  async appAlert() {
    const alert = await this.alertController.create({
      header: 'Atención',
      subHeader: '',
      message: 'Esta es una versión para guardias y monitoristas, ¿estás en busca de la aplicación familiar?',
      buttons: [
        {
          text: 'Continuar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'App familiar',
          handler: (data: any) => {
            //this.getApp();
          }
        }
      ]
    });
    await alert.present();
  }
}

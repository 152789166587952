import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'circlequery',
  pure: false
})
export class CircleQueryPipe implements PipeTransform {
  transform(items: any[], query: string): any {
    if (query) {
      return items.filter(item => !item.key.search(query));
    } else {
      return items;
    }
  }
}

import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { FollowsheetComponent } from '@app/shared/followsheet/followsheet.component';
import { ShellModule } from '@app/shell/shell.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { CirclePipe } from './circle.pipe';
import { CircleQueryPipe } from './circlequery.pipe';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    IonicModule,
    HomeRoutingModule,
    FormsModule,
    ShellModule,
    AgmCoreModule
  ],
  entryComponents: [HomeComponent, FollowsheetComponent],
  declarations: [HomeComponent, CirclePipe, CircleQueryPipe]
})
export class HomeModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'circle',
  pure: false
})
export class CirclePipe implements PipeTransform {
  transform(items: any[]): any {
    if (items) {
      return items.filter(item => item.keyword);
    }
  }
}

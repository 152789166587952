import { Component, OnInit, Input } from '@angular/core';

import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatasheetService } from '@app/core/db/datasheet.service';
import { Datasheet } from '@app/core/db/datasheet.model';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { CredentialsService } from '@app/core';
import { take } from 'rxjs/operators';
import { firestore } from 'firebase';

@Component({
  selector: 'app-datasheet',
  templateUrl: './datasheet.component.html',
  styleUrls: ['./datasheet.component.scss']
})
export class DatasheetComponent implements OnInit {
  @Input() id: string;
  @Input() type: string;
  @Input() alias: string;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '50',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Detalla la ficha a continuación',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [{ class: 'sans-serif', name: 'Roboto' }],
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['subscript', 'superscript'], ['font'], ['clearFormatting'], ['insertImage', 'insertVideo']]
  };
  circleId: string;
  datasheet: Datasheet;
  protocol: string;
  personId: string;
  aliasId: string;
  editmode = false;
  datasheetType: string;

  private itemDoc: AngularFirestoreDocument<Datasheet>;
  private item: Observable<Datasheet>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore,
    private credentialsService: CredentialsService
  ) {}
  ngOnInit() {}
  ionViewDidEnter() {
    if (this.type === 'circle') {
      this.circleId = this.id;
    } else if (this.type === 'person') {
      this.personId = this.id;
    }

    switch (this.type) {
      case 'person':
      case 'user':
        this.datasheetType = 'users';
        break;
      case 'gps':
        this.datasheetType = 'personal_gps';
        break;
      case 'circle':
        this.datasheetType = 'circles';
        break;
      case 'car':
        this.datasheetType = 'cars';
        break;
      default:
        this.datasheetType = 'users';

        break;
    }

    this.afs
      .doc<Datasheet>(`datasheet/${this.datasheetType}/children/${this.id}`)
      .valueChanges()
      .pipe(take(1))
      .subscribe(data => {
        if (data) {
          this.datasheet = data;
          this.protocol = this.datasheet.body;
        }
      });
  }

  saveData() {
    switch (this.type) {
      case 'person':
      case 'user':
        this.datasheetType = 'users';
        break;
      case 'circle':
        this.datasheetType = 'circles';
        break;
      case 'car':
        this.datasheetType = 'cars';
        break;
      case 'gps':
        this.datasheetType = 'personal_gps';
        break;
      default:
        this.datasheetType = 'users';
        break;
    }

    this.afs.doc<Datasheet>(`datasheet/${this.datasheetType}/children/${this.id}`).set({
      id: this.id,
      body: this.protocol,
      lastUpdate: { user: this.credentialsService.credentials.username, timestamp: firestore.Timestamp.now() }
    });

    this.editmode = !this.editmode;
  }
  editToggle() {
    this.editmode = !this.editmode;
  }
}

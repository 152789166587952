import { Component, OnInit, ElementRef, NgZone, ViewChild, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { ToastController, AlertController, ModalController, LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { take, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Map, latLng, tileLayer, Layer, marker, Marker } from 'leaflet';
import { basicMap, hybridMap, darkMatter, setControl, mapOptions } from '@app/core/map/mapconstants.module';
import { firestore } from 'firebase';

@Component({
  selector: 'app-followsheet',
  templateUrl: './followsheet.component.html',
  styleUrls: ['./followsheet.component.css']
})
export class FollowsheetComponent implements OnInit {
  @Output() gplaceResutl = new EventEmitter<{}>();
  @Output() greference = new EventEmitter<{}>();
  @ViewChild('search', {
    static: false
  })
  search: ElementRef;
  searchControl: FormControl;
  searchElementRef: ElementRef;
  place: google.maps.places.PlaceResult = null;
  date: any = {
    start: moment().format(),
    end: moment().format()
  };
  timeDuration: number = 0;
  dateMin: String = moment().format();
  search_timeout: any = null;
  users: any = [];
  selectedUser: any = null;
  loading: any;
  followTypes = [
    {
      type: 'time',
      name: 'Tiempo'
    },
    {
      type: 'destination',
      name: 'Origen - Destino'
    }
  ];
  followType: {
    type: string;
    name: string;
  } = {
    type: null,
    name: null
  };
  map: Map;
  mapLayer = basicMap;

  constructor(
    private afs: AngularFirestore,
    private afd: AngularFireDatabase,
    public alertCtrl: AlertController,
    private modalController: ModalController,
    private loadingCtrl: LoadingController,
    private ngZone: NgZone,
    private mapLoader: MapsAPILoader
  ) {}

  ngOnInit(): void {
    this.searchControl = new FormControl();
    this.mapLoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.search.nativeElement, {
        types: ['establishment', 'geocode'],
        componentRestrictions: {
          country: 'MX'
        }
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.place = autocomplete.getPlace();
          this.search.nativeElement.value = '';
        });
      });
    });
  }

  ionViewWillEnter() {
    this.map = new Map('mapIdfollow', {
      center: [19.4319716, -99.1356141],
      zoom: 10,
      layers: [basicMap]
    });

    setTimeout(() => {
      this.mapLayer.redraw();
      this.map.invalidateSize();
    }, 2000);
  }

  toReference($event: any) {
    this.greference.emit($event);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async acceptFollowme() {
    const resp = await this.createFollow();
    /* if (resp) {
      this.modalController.dismiss();
    } */
  }

  stopPropagationEvt(event: { stopPropagation: () => void }) {
    event.stopPropagation();
  }

  changeRange(event: { value: any }) {
    let time = event.value;
    if (time == 0) {
      this.date.end = null;
    } else {
      this.date.end = this.getEndTime(this.date.start, this.timeDuration);
    }
  }

  updateDate(event: any) {
    if (this.timeDuration != 0) {
      this.date.end = this.getEndTime(this.date.start, this.timeDuration);
    }
  }

  getEndTime(dateStar: any, minutes: number): String {
    let d = new Date(dateStar);
    d.setMinutes(d.getMinutes() + minutes);
    return moment(d).format();
  }

  async createFollow() {
    try {
      let data = {
        circle: this.selectedUser.cid,
        coord: new firestore.GeoPoint(
          this.selectedUser.lastPost.coord.latitude,
          this.selectedUser.lastPost.coord.longitude
        ),
        date_end: new Date(this.date.end),
        date_start: new Date(this.date.start),
        uid: this.selectedUser.key,
        username: this.selectedUser.username,
        active: false,
        following: false,
        published: new Date(),
        type: this.followType['type']
      };
      let extraData = {};

      switch (data.type) {
        case 'time':
          extraData = {
            duration: this.timeDuration
          };

          data = {
            ...data,
            ...extraData
          };

          break;
        case 'destination':
          let coord = new firestore.GeoPoint(this.place.geometry.location.lat(), this.place.geometry.location.lng());
          extraData = {
            destination: {
              coord: coord
            }
          };

          data = {
            ...data,
            ...extraData
          };

          break;
      }

      let validTime = moment(data.date_start).isAfter();

      console.log(data.date_start, moment(data.date_start), moment(data.date_start).isAfter());
      /* if (!validTime) {
        // Fecha y hora no valida. Selecciono fecha pasada
        this.presentAlert('Error al generar servicio', 'Seleccione otra fecha y hora');
        return false;
      } */

      let hasFollow = await this.hasFollow();

      const response = await this.afs.collection('follows').add(data);
      await this.afd.object('circles/' + this.selectedUser.cid + '/members/' + this.selectedUser.key).update({
        followKey: response.id
      });

      let tracking = {
        tracking: ''
      };
      tracking.tracking = 'live';
      await this.afd.object('circles/' + this.selectedUser.cid + '/members/' + this.selectedUser.key).update(tracking);

      this.presentAlert('Acompáñame activado', 'Los datos se verán reflejados en War Room');

      return true;
    } catch (error) {
      console.error(error);
      //this.showAlert('Error', 'Unreached user data');
      return false;
    }
  }

  async hasFollow() {
    let payloadCurrent = await this.afd
      .object('users/' + this.selectedUser.key)
      .valueChanges()
      .pipe(take(1))
      .toPromise();

    const thisUser: any = payloadCurrent;

    return thisUser.followKey && thisUser.followKey != '';
  }

  async presentAlert(message: string, submessage: string) {
    const alert = await this.alertCtrl.create({
      header: message,
      subHeader: submessage,
      buttons: ['Aceptar']
    });

    await alert.present();

    setTimeout(() => {
      alert.dismiss();
    }, 3000);
  }

  getItems(ev: any) {
    const val = ev.target.value;

    if (val) {
      if (this.search_timeout) {
        clearTimeout(this.search_timeout);
      }

      this.search_timeout = setTimeout(async () => {
        this.loading = await this.loadingCtrl.create();
        this.searchCharacter(val);
      }, 1000);
    }
  }

  async searchCharacter(name: string) {
    let namez = name.toUpperCase();
    let data = this.afd
      .list('users', ref =>
        ref
          .orderByChild('username')
          .startAt(namez)
          .endAt(namez + '\uf8ff')
          .limitToFirst(10)
      )
      .snapshotChanges()
      .subscribe(users => {
        let tmpUsers = [];
        for (let index = 0; index < users.length; index++) {
          const element = users[index];
          const data: any = element.payload.val();
          data.key = element.key;

          tmpUsers.push(data);
        }
        this.users = tmpUsers;
        this.loading.dismiss();
      });
  }

  async select(user: any) {
    this.users = [];
    this.selectedUser = user;

    try {
      let hasFollow = await this.hasFollow();

      if (hasFollow) {
        this.selectedUser = null;
        this.presentAlert('Atención', 'Ya tiene activo un servicio Acompáñame');
      }
    } catch (error) {}
  }

  selectType($event: any) {
    const indx: number = this.followTypes.findIndex(x => {
      return x.type === $event.detail.value;
    });
    this.followType = this.followTypes[indx];
  }
}

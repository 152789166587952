import { Logger, I18nService, AuthenticationService, untilDestroyed, CredentialsService } from '@app/core';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApiService } from '@app/shared/services/api/api.service';
import { DataService } from '../services/data/data.service';
import { environment } from '@env/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-circlesearch',
  templateUrl: './circlesearch.component.html',
  styleUrls: ['./circlesearch.component.css']
})
export class CirclesearchComponent implements OnInit {
  @Input() circlesearch: any;
  @Input() action: string;

  public editForm: FormGroup;
  public createForm: FormGroup;

  constructor(
    private router: Router,
    public modalController: ModalController,
    public toastController: ToastController,
    public alertController: AlertController,
    public afd: AngularFireDatabase,
    private afs: AngularFirestore,
    public formBuilder: FormBuilder,
    public credentialsService: CredentialsService,
    public apiService: ApiService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    if (this.dataService.circles.length < 1) {
      this.dataService.getCircles();
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  toCircle(circle: any) {
    this.closeModal();
    this.router.navigate([`/circle/${circle.id}`]);
  }

  capitalize(data: any) {
    return data.toString().toUpperCase();
  }

  stopPropagationEvt(event: { stopPropagation: () => void }) {
    event.stopPropagation();
  }

  async copyInputMessage(copyText: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', copyText);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    const toast = await this.toastController.create({
      message: 'Información copiada al portapapeles.',
      duration: 1500
    });
    toast.present();
  }

  async presentAlert(message: string, submessage: string) {
    const alert = await this.alertController.create({
      header: message,
      subHeader: submessage,
      buttons: ['Aceptar']
    });

    await alert.present();

    setTimeout(() => {
      alert.dismiss();
    }, 3000);
  }
}

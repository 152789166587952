import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialsService } from '@app/core';
import { DataService } from '../services/data/data.service';
import { ApiService } from '../services/api/api.service';
import { Coords, Emergency, LocationPost } from '@app/core/db/emergency.model';
import { basicMap, mapOptions, setControl } from '@app/core/map/mapconstants.module';
import { Follow } from '@app/core/db/follow.model';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { LayerGroup, Map } from 'leaflet';
import * as L from 'leaflet';
import * as cdmxQuadData from '@app/core/map/cdmxquadrants.json';
import * as agebs from '@app/core/map/agebs.json';
import * as zoneData from '@app/core/map/trust-11b7d-risk-zones-export.json';
import { Subject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { GoogleMap } from '@angular/google-maps';
import { createImportTypeNode } from 'typescript';
const omnivoreModule = require('../../../assets/js/leaflet-omnivore.min.js');

@Component({
  selector: 'app-google',
  templateUrl: './agm.component.html',
  styleUrls: ['./agm.component.css']
})
export class AgmComponent implements OnInit {
  userAuth: any;
  omnivore = omnivoreModule;
  sendReadyMap = new Subject<boolean>();
  sendReadyMap$ = this.sendReadyMap.asObservable();
  map: Map;
  mapLayer = basicMap;
  quadPol = new L.LayerGroup();
  zonePol = new L.LayerGroup();
  activeEmergency = new L.LayerGroup();
  emergencyGP = new L.LayerGroup();
  oldEmergencyGP = new L.LayerGroup();
  postGp = new L.LayerGroup();
  postCarGp = new L.LayerGroup();
  lastLocGp = new L.LayerGroup();
  lastLocMk = new L.LayerGroup();
  lastLocLn = new L.LayerGroup();
  lastPosts = new L.LayerGroup();
  lastCarsPosts = new L.LayerGroup();
  membersPost = new L.LayerGroup();
  unitsPost = new L.LayerGroup();
  postSng = new L.LayerGroup();
  crimeGp = new L.LayerGroup();
  layerOrigin = new L.LayerGroup();
  layerDestination = new L.LayerGroup();
  corporateGeoLayer = new L.LayerGroup();
  geofencesLayer = new L.LayerGroup();
  arrowLine = new L.LayerGroup();
  tmpSng: L.Marker;
  quadrantsCdmx = cdmxQuadData.default;
  agebs = agebs.default;
  setControl = setControl;
  lineArr: any[] = [];
  crimeCats = [
    'HOMICIDIO DOLOSO',
    'LESIONES DOLOSAS POR DISPARO DE ARMA DE FUEGO',
    'ROBO A CASA HABITACIÓN CON VIOLENCIA',
    'ROBO A CUENTAHABIENTE SALIENDO DEL CAJERO CON VIOLENCIA',
    'ROBO A NEGOCIO CON VIOLENCIA',
    'ROBO A PASAJERO A BORDO DE MICROBUS CON Y SIN VIOLENCIA',
    'ROBO A PASAJERO A BORDO DE TAXI CON VIOLENCIA',
    'ROBO A PASAJERO A BORDO DEL METRO CON Y SIN VIOLENCIA',
    'ROBO A REPARTIDOR CON Y SIN VIOLENCIA',
    'ROBO A TRANSEUNTE EN VÍA PÚBLICA CON Y SIN VIOLENCIA',
    'ROBO DE VEHÍCULO CON Y SIN VIOLENCIA',
    'VIOLACIÓN'
  ];
  zoneLevels = [
    {
      id: 'very_high',
      name: 'MUY ALTO',
      color: '#cc3232'
    },
    {
      id: 'high',
      name: 'ALTO',
      color: '#db7b2b'
    },
    {
      id: 'medium',
      name: 'MEDIO',
      color: '#e7b416'
    },
    {
      id: 'low',
      name: 'BAJO',
      color: '#99c140'
    },
    {
      id: 'very_low',
      name: 'MUY BAJO',
      color: '#2dc937'
    },
    {
      id: 'none',
      name: 'NINGUNO',
      color: '#808080'
    }
  ];
  corporategeo: Array<any> = [];
  tmpcorporategeo: Array<any> = [];
  drawGeofences: boolean = true;
  corZonesSub: Subscription;
  l = L;
  index: any;

  @ViewChild(GoogleMap, { static: false }) googlemap: GoogleMap;
  controls: Array<{
    id: string;
    name: string;
    value: boolean;
    type: string;
    layer: any;
    markers?: string;
    line?: string;
  }> = [];
  quadLayer = new google.maps.Data();
  agebLayer = new google.maps.Data();
  corpGeofencesLayer = new google.maps.Data();

  options: google.maps.MapOptions = {
    fullscreenControl: false
  };
  center = {
    lat: mapOptions.center[0],
    lng: mapOptions.center[1]
  };

  /* Home */
  homeMarkers = new google.maps.MVCObject();
  newEmergencies: Array<google.maps.Marker> = [];
  oldEmergencies: Array<google.maps.Marker> = [];
  /* Emergency */
  mapMarkers = new google.maps.MVCObject();
  lastLocMarkers: Array<google.maps.Marker> = [];
  pointsMarkers: Array<google.maps.Marker> = [];
  pointsLine: google.maps.Polyline = null;
  /* Circle */
  usersMarkers: Array<google.maps.Marker> = [];
  unitsMarkers: Array<google.maps.Marker> = [];
  gpsMarkers: Array<google.maps.Marker> = [];
  geofencesCircles: Array<google.maps.Circle> = [];
  /* Person */
  personMarker: google.maps.Marker = null;
  /* Car */
  carMarker: google.maps.Marker = null;
  /* Follow me */
  lastMarker: google.maps.Marker = null;
  /* General */
  cluster: any[] = [];

  constructor(
    public datePipe: DatePipe,
    private credentialsService: CredentialsService,
    private dataService: DataService,
    public apiService: ApiService,
    public http: HttpClient,
    public router: Router
  ) {
    this.userAuth = this.credentialsService.credentials;
  }

  async ngOnInit() {
    this.createMap();
  }

  createMap() {
    setTimeout(async () => {
      try {
        this.initMap();
      } catch (error) {
        console.error('error loading map', error);
      }
    }, 1500);
  }

  removeMap() {
    this.map.eachLayer((layer: L.Layer) => {
      this.map.removeLayer(layer);
    });
    this.map.remove();
    document.getElementById('mapId').outerHTML = '';
  }

  ionViewWillLeave() {
    this.sendReadyMap.next(false);
  }

  async initMap() {
    const map_container = !!document.getElementById('mapId');
    if (map_container) {
      // leaflet maps
      this.map = await new Map('mapId', mapOptions);

      const tree: UrlTree = this.router.parseUrl(this.router.url);
      const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
      const s: UrlSegment[] = g.segments;
      const rootPath = s[0].path;

      if (rootPath == 'geofences') {
        this.map.pm.addControls({
          position: 'topleft',
          drawMarker: false,
          drawPolyline: false,
          drawCircleMarker: false,
          cutPolygon: false
        });

        this.map.pm.setGlobalOptions({
          layerGroup: this.corporateGeoLayer,
          snappingOrder: ['Marker', 'CircleMarker', 'Circle', 'Line', 'Polygon', 'Rectangle'],
          panes: { vertexPane: 'markerPane', layerPane: 'overlayPane', markerPane: 'markerPane' }
        });

        this.subscribetoevents();
      } else {
        this.cuadrantsMapping();
        if (environment.GROUP == '' && this.credentialsService.credentials.group == '') {
          this.riskZoneMapping();
        }
      }

      this.sendReadyMap.next(true);
      this.mapLayer.addTo(this.map);
      this.map.addControl(this.setControl);
      setTimeout(() => {
        this.refreshMap();
      }, 1000);
    } else {
      // google maps
      //LAYER TRAFICO
      const trafficLayer = new google.maps.TrafficLayer();

      this.controls.push({
        id: 'traffic',
        name: 'Tráfico',
        value: this.dataService.traffic,
        type: 'layer',
        layer: trafficLayer
      });

      this.cuadrantsMapping();
      if (environment.GROUP == '' && this.credentialsService.credentials.group == '') {
        this.riskZoneMapping();
      } else {
        const group = environment.GROUP != '' ? environment.GROUP : this.credentialsService.credentials.group;
        this.corporateZones(group.toLowerCase());
      }
    }
  }

  async clearMap() {
    this.controls = [];
  }

  refreshMap() {
    this.mapLayer.redraw();
    this.map.invalidateSize();
  }

  riskZoneMapping() {
    //OLD VERSION FOR LEAFLET
    /* const zones = zoneData.default;
    // tslint:disable-next-line: forin
    for (const x in zones) {
      const y = new L.Polygon(zones[x].points, {
        color: zones[x].fillColor
      });
      this.zonePol.addLayer(y);
    }
    this.map.addLayer(this.zonePol);
    this.setControl.addOverlay(this.zonePol, 'Zonas de Riesgo'); */

    const myStyle: google.maps.Data.StyleOptions = {
      fillColor: '#6fcfd6',
      fillOpacity: 0.2,
      strokeColor: '#7dd4d9',
      strokeWeight: 2
    };

    const map = this.googlemap.data.getMap();

    this.agebLayer = new google.maps.Data({ map: null });
    this.agebLayer.setStyle(myStyle);
    this.agebs.data.forEach((single: any) => {
      //this.agebLayer.addGeoJson(single.geojson);
      this.addAllPolygonCorridors(single);
    });

    let this_ = this;
    /* this.agebLayer.addListener('click', async function(event) {
      var feat = event.feature;
      console.log(event); */

    /* const risk = await this_.apiService.getRisk(event.);
      console.log(risk); */
    /*  var feat = event.feature;
      console.log(feat);
      const infowindow = new google.maps.InfoWindow();

      const popUp =
        '<p><small>Sector</small> <b>' +
        feat.getProperty('sector') +
        '</b><br><small>Delegación</small> ' +
        feat.getProperty('deleg') +
        '<br><small>Nomenclatura</small> ' +
        feat.getProperty('nomenclatu') +
        '<br><small>Zona</small> ' +
        feat.getProperty('zona') +
        '<br><small>Teléfono 1</small> ' +
        feat.getProperty('tel1') +
        '<br><small>Teléfono 2</small> ' +
        feat.getProperty('tel2') +
        '</p>';

      infowindow.setContent(popUp);
      infowindow.setPosition(event.latLng);
      infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -34) });
      infowindow.open(map); */
    /* }); */

    this.controls.push({
      id: 'agebs',
      name: 'AGEBS',
      value: false,
      type: 'layer',
      layer: this.agebLayer
    });
  }

  cuadrantsMapping() {
    const dataArr: any[] = this.quadrantsCdmx.features;

    const myStyle: google.maps.Data.StyleOptions = {
      fillColor: '#6fcfd6',
      fillOpacity: 0.2,
      strokeColor: '#7dd4d9',
      strokeWeight: 1.5
    };

    const map = this.googlemap.data.getMap();

    this.quadLayer = new google.maps.Data({ map: null });
    this.quadLayer.setStyle(myStyle);
    dataArr.forEach((single: any) => {
      this.quadLayer.addGeoJson(single);
    });

    this.quadLayer.addListener('click', function(event) {
      var feat = event.feature;
      const infowindow = new google.maps.InfoWindow();

      const popUp =
        '<p><small>Sector</small> <b>' +
        feat.getProperty('sector') +
        '</b><br><small>Delegación</small> ' +
        feat.getProperty('deleg') +
        '<br><small>Nomenclatura</small> ' +
        feat.getProperty('nomenclatu') +
        '<br><small>Zona</small> ' +
        feat.getProperty('zona') +
        '<br><small>Teléfono 1</small> ' +
        feat.getProperty('tel1') +
        '<br><small>Teléfono 2</small> ' +
        feat.getProperty('tel2') +
        '</p>';

      infowindow.setContent(popUp);
      infowindow.setPosition(event.latLng);
      infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -34) });
      infowindow.open(map);
    });

    this.controls.push({
      id: 'quadrants',
      name: 'Cuadrantes CDMX',
      value: false,
      type: 'layer',
      layer: this.quadLayer
    });
  }

  async clearLayer() {
    try {
      this.map.eachLayer((layer: L.Layer) => {
        layer.remove();
      });
    } catch (error) {}
  }

  //HOME POSTS
  postHomeMapping() {
    try {
      const map = this.googlemap.data.getMap();
      this.homeMarkers.setValues({ old: null, new: map });
      this.controls.push(
        {
          id: 'old',
          name: 'Pasadas',
          value: false,
          type: 'markers',
          layer: this.homeMarkers,
          markers: 'oldEmergencies'
        },
        {
          id: 'new',
          name: 'Activas',
          value: true,
          type: 'markers',
          layer: this.homeMarkers,
          markers: 'newEmergencies'
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  addAllPolygonCorridors(data: any /* , opacity: number, color: string */) {
    // Se obtienen las coordenadas del polígono y se crea
    const geometry = data.geojson.geometry;
    const coords: [[number, number]] = geometry.coordinates[0];
    const LatLng = coords.map(c => new google.maps.LatLng(c[1], c[0]));
    let color = '#2dc937';

    switch (data.risk ? data.risk.actual_risk : 1) {
      case 1:
        color = '#2dc937';

        break;
      case 2:
        color = '#99c140';

        break;
      case 3:
        color = '#e7b416';
        break;
      case 4:
        color = '#db7b2b';

        break;
      case 5:
        color = '#cc3232';

        break;

      default:
        break;
    }

    const polygon = new google.maps.Polygon({
      paths: LatLng,
      strokeColor: color, //color,
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: color, //color,
      fillOpacity: 0.4 //opacity > 1 ? 1 : opacity
    });

    /* polygon['data'] = {
      data: data
    }; */

    // Evento que mostrara el infoWindow de los polígonos
    polygon.addListener('click', (event: any) => {
      /* console.log(polygon['data']); */
      // Cerrar infowindow de los marcadores
      //this.lastInfoWindow?.close();

      let contentString: string = '';

      // Se asignan variables al infowindow
      /* this.infoWindowPolygonCorridors.setContent(contentString);
      this.infoWindowPolygonCorridors.setPosition(event.latLng);
      this.infoWindowPolygonCorridors.open(this.map); */
    });

    //polygon.setMap(this.map);
    polygon.bindTo('map', this.agebLayer);
    return polygon;
  }

  singleHomePost(data: Emergency) {
    let iconUrl: string;
    let iconSize: number;
    if (!data.emergencySolved) {
      iconUrl = './assets/icon/' + data.type + '-sos.png';
      iconSize = 50;
    } else {
      iconUrl = './assets/icon/' + data.type + '-old.png';
      iconSize = 32;
    }

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const mark = new google.maps.Marker({
      position: { lat: data.coord.latitude, lng: data.coord.longitude },
      icon: image
    });

    const person = (data.username ? data.username : '') + ' ' + (data.owner ? data.owner : '');
    const popup = '<h5>' + data.circle + '</h5>' + '<h6>' + person + '</h6>' + '<p>' + data.published.toDate() + '</p>';

    const infowindow = new google.maps.InfoWindow({
      content: popup
    });

    mark.addListener('click', () => {
      infowindow.open(map, mark);
    });

    if (!data.emergencySolved) {
      this.newEmergencies.push(mark);
      mark.bindTo('map', this.homeMarkers, 'new');
    } else {
      this.oldEmergencies.push(mark);
      mark.bindTo('map', this.homeMarkers, 'old');
    }
  }

  clearHome() {
    for (let index = 0; index < this.newEmergencies.length; index++) {
      const element = this.newEmergencies[index];
      element.setMap(null);
    }

    for (let index = 0; index < this.oldEmergencies.length; index++) {
      const element = this.oldEmergencies[index];
      element.setMap(null);
    }

    this.newEmergencies = [];
    this.oldEmergencies = [];
  }

  //EMERGENCY POSTS
  postEmergencyMapping() {
    try {
      this.lineArr = [];
      const map = this.googlemap.data.getMap();
      this.mapMarkers.setValues({ last: null, points: null, line: true, emMk: map });
      this.controls.push(
        {
          id: 'last',
          name: 'Últimas ubicaciones',
          value: false,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'lastLocMarkers'
        },
        {
          id: 'points',
          name: 'Live Tracking (puntos)',
          value: false,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'pointsMarkers'
        },
        {
          id: 'line',
          name: 'Live Tracking (línea)',
          value: true,
          type: 'line',
          layer: this.mapMarkers,
          line: 'pointsLine'
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  singleemergencypost(data: Emergency, username: string) {
    let iconUrl: string;
    let iconSize: number;
    iconUrl = './assets/icon/' + data.type + '-sos.png';
    iconSize = 68;

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const mark = new google.maps.Marker({
      position: { lat: data.coord.latitude, lng: data.coord.longitude },
      icon: image
    });

    const popup =
      '<ion-text color="danger"><h3 class="ion-text-center">Emergencia</h3></ion-text>' +
      '<h5 class="ion-text-center">' +
      username +
      '</h5>' +
      '<p>' +
      this.datePipe.transform(data.published.seconds * 1000, 'medium') +
      '<br>' +
      data.direction +
      '</p>';

    const infowindow = new google.maps.InfoWindow({
      content: popup
    });

    mark.addListener('click', () => {
      infowindow.open(map, mark);
    });

    mark.bindTo('map', this.mapMarkers, 'emMk');

    google.maps.event.trigger(mark, 'click');
  }

  clearEmergency() {
    this.clearemergencypoints();
    this.clearemergencyline();
  }

  clearemergencypoints() {
    for (let index = 0; index < this.pointsMarkers.length; index++) {
      const element = this.pointsMarkers[index];
      element.setMap(null);
    }
    this.pointsMarkers = [];
  }

  clearemergencyline() {
    this.pointsLine.setMap(null);
    this.pointsLine = null;
  }

  //CLEAR ZONE

  clearCircle() {
    this.removeCircle();
  }

  clearPerson() {
    this.removePerson();
  }

  clearCar() {
    this.removeCar();
  }

  clearFollow() {
    this.removeFollow();
  }

  //REMOVE ZONE
  removeHome() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.emergencyGP);
    this.setControl.removeLayer(this.oldEmergencyGP);
  }

  removeEmergency() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.setControl.removeLayer(this.lastPosts);
    this.setControl.removeLayer(this.lastLocGp);
    this.setControl.removeLayer(this.lastLocLn);
    this.map.removeLayer(this.activeEmergency);
  }

  removeCircle() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.membersPost);
    this.setControl.removeLayer(this.unitsPost);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.membersPost.clearLayers();
    this.unitsPost.clearLayers();

    this.usersMarkers.forEach(marker => {
      marker.unbindAll();
      marker.setMap(null);
    });
    this.unitsMarkers.forEach(marker => {
      marker.unbindAll();
      marker.setMap(null);
    });
    this.gpsMarkers.forEach(marker => {
      marker.unbindAll();
      marker.setMap(null);
    });
  }

  removePerson() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.postSng.clearLayers();
    this.postGp.clearLayers();
    this.map.removeLayer(this.postSng);
    this.map.removeLayer(this.postGp);
  }

  removeCar() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.postSng.clearLayers();
    this.postGp.clearLayers();
    this.map.removeLayer(this.postSng);
    this.map.removeLayer(this.postGp);
  }

  //EMERGENCY POSTS

  postsMapping(posts: Array<any>) {
    this.postGpMapping(posts);
  }

  async postGpMapping(posts: Array<any>) {
    this.postGp.clearLayers();
    await posts.map(data => {
      let username;
      switch (data.type) {
        case 'car':
          username = data.cid + ' - ' + data.name_device;
          break;

        default:
          username = data.username ? data.username : data.owner;
          break;
      }

      const icon = L.icon({
        iconUrl: './assets/icon/' + data.type + '-old.png',
        iconAnchor: [25, 5],
        iconSize: [50, 50],
        popupAnchor: [0, -5]
      });
      const popUp =
        '<h4 class="ion-text-center">' +
        username +
        '</h4>' +
        '<p>' +
        data.direction +
        '</p>' +
        '<p>' +
        this.datePipe.transform(data.published, 'medium') +
        '</p>';
      const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
        icon
      })
        .bindPopup(popUp)
        .addTo(this.postGp);
    });
  }

  async lastLocMapping(lastlocs: Array<LocationPost>) {
    this.lineArr = [];
    await lastlocs
      .sort((a, b) => a.published.seconds - b.published.seconds)
      .forEach(item => {
        this.lineArr.push({ lat: item.coord.latitude, lng: item.coord.longitude });
      });
  }

  lastlocmapping(data: LocationPost, index: number, lastprinted: LocationPost, keep: boolean) {
    let iconUrl: string;
    let iconSize: number;
    let popup: string;
    let draw: boolean = false;
    let type: string;

    iconSize = 40;

    if (index == 0) {
      //console.log('Case : Last Loc', len + ' Index', index + 1, 'Length', len === index + 1);
      // si es el último objeto se pinta de azul (live location)
      iconUrl = './assets/icon/circle-reg.png';
      popup = '<span class="my-div-span">Última <br> ubicación</span>';
      //type = 'reg';
      //type = keep ? null : 'history';
      iconSize = 50;
      draw = true;
    } else {
      if (index == this.lineArr.length - 1) {
        iconUrl = './assets/icon/pin-reg.png';
        popup = '<img class="my-div-image" src=/>' + '<span class="my-div-span">Inicio</span>';
        type = 'old';
        draw = true;
      } else if (this.getDistanceFromLatLonInKm(data.coord, lastprinted.coord) > 0.15) {
        // si hay mas de 50 mts de diferencia del último punto
        iconUrl = './assets/icon/circle-old.png';
        iconSize = 24;
        type = 'old';
        draw = true;
      }
    }

    if (draw) {
      const image: google.maps.ReadonlyIcon = {
        url: iconUrl,
        scaledSize: new google.maps.Size(iconSize, iconSize),
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(0, 0)
      };

      this.printMarker(image, data, keep, index);
    } else {
      if (this.pointsMarkers[index - 1]) {
        this.pointsMarkers[index - 1]['map'] = null;
        this.pointsMarkers[index - 1].setMap(null);
      }
    }
  }

  addLine() {
    const map = this.googlemap.data.getMap();

    if (!this.pointsLine) {
      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 4
      };
      const line = new google.maps.Polyline({
        path: this.lineArr,
        geodesic: true,
        strokeColor: '#ff6900',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
            repeat: '50px'
          }
        ]
      });
      this.pointsLine = line;

      this.pointsLine.bindTo('map', this.mapMarkers, 'line');
    } else {
      const updatedPath = [];

      for (let index = 0; index < this.lineArr.length; index++) {
        const element = this.lineArr[index];
        updatedPath.push(new google.maps.LatLng(element.lat, element.lng));
      }
      this.pointsLine.setPath(updatedPath);
    }

    const idx = this.controls.findIndex(x => {
      return x.id === 'line';
    });

    const view = this.controls[idx].value ? map : null;

    this.mapMarkers.set('line', view);
  }

  /* ARROWS */
  /* GET MORE INFO AT: https://stackoverflow.com/a/58686215 */
  getArrows(arrLatlngs: any, color: any, arrowCount: any, mapObj: any) {
    if (typeof arrLatlngs === undefined || arrLatlngs == null || !arrLatlngs.length || arrLatlngs.length < 2) return [];

    if (typeof arrowCount === 'undefined' || arrowCount == null) arrowCount = 1;

    if (typeof color === 'undefined' || color == null) color = '';
    else color = 'color:' + color;

    var result = [];
    this.index = 0;
    for (var i = 1; i < arrLatlngs.length; i++) {
      var icon = L.divIcon({
        className: 'arrow-icon',
        bgPos: [3, 3],
        html:
          '<div style="' +
          color +
          ';transform: rotate(' +
          this.getAngle(arrLatlngs[i - 1], arrLatlngs[i], -1).toString() +
          'deg)"><ion-icon name="caret-forward-outline" style="font-size:2em"></ion-icon></div>'
      });
      for (var c = 1; c <= arrowCount; c++) {
        const marker = L.marker(this.myMidPoint(arrLatlngs[i], arrLatlngs[i - 1], c / (arrowCount + 1), mapObj), {
          icon: icon
        });
        result.push(marker);
      }
    }
    return result;
  }

  getAngle(latLng1: any, latlng2: any, coef: any) {
    var dy = latlng2[0] - latLng1[0];
    var dx = Math.cos((Math.PI / 180) * latLng1[0]) * (latlng2[1] - latLng1[1]);
    var ang = (Math.atan2(dy, dx) / Math.PI) * 180 * coef;
    return ang.toFixed(2);
  }

  myMidPoint(latlng1: any, latlng2: any, per: any, mapObj: any) {
    if (!mapObj) throw new Error('map is not defined');

    var halfDist,
      segDist,
      dist,
      p1,
      p2,
      ratio,
      points = [];

    p1 = mapObj.project(new L.LatLng(latlng1[0], latlng1[1]));
    p2 = mapObj.project(new L.LatLng(latlng2[0], latlng2[1]));

    halfDist = this.distanceTo(p1, p2) * per;

    if (halfDist === 0) return mapObj.unproject(p1);

    dist = this.distanceTo(p1, p2);

    if (dist > halfDist) {
      ratio = (dist - halfDist) / dist;
      var res = mapObj.unproject(this.Point(p2.x - ratio * (p2.x - p1.x), p2.y - ratio * (p2.y - p1.y)));
      return [res.lat, res.lng];
    }
  }

  distanceTo(p1: any, p2: any) {
    var x = p2.x - p1.x,
      y = p2.y - p1.y;

    return Math.sqrt(x * x + y * y);
  }

  toPoint(x: any, y: any, round?: any) {
    if (x instanceof this.Point) {
      return x;
    }
    if (x.isArray()) {
      return this.Point(x[0], x[1]);
    }
    if (x === undefined || x === null) {
      return x;
    }
    if (typeof x === 'object' && 'x' in x && 'y' in x) {
      return this.Point(x.x, x.y);
    }
    return this.Point(x, y, round);
  }

  Point(x: any, y: any, round?: any) {
    const x1 = round ? Math.round(x) : x;
    const y1 = round ? Math.round(y) : y;

    return { x: x1, y: y1 };
  }
  /* END 
  ARROWS */

  printMarker(icon: google.maps.ReadonlyIcon, data: LocationPost, keep: boolean, index: number) {
    const popUp =
      '<h4 class="ion-text-center">' +
      (data.username ? data.username : data['name_device']) +
      '</h4>' +
      '<p>' +
      data.direction +
      '</p>' +
      '<p>' +
      this.datePipe.transform(data.published.toDate(), 'medium') +
      '</p>';
    const position = { lat: data.coord.latitude, lng: data.coord.longitude };
    const map = this.googlemap.data.getMap();

    const mark = new google.maps.Marker({
      position: position,
      icon: icon
    });

    const infowindow = new google.maps.InfoWindow({
      content: popUp
    });

    mark['infowindow'] = infowindow;
    mark['data'] = data;

    if (index < 1) {
      //if reg, index == 0

      if (!keep) {
        const idx = this.pointsMarkers.length;
        google.maps.event.addListener(mark, 'click', () => {
          this.pointsMarkers[idx]['infowindow'].open(map, mark);
        });

        this.pointsMarkers.push(mark);
        this.pointsMarkers[this.pointsMarkers.length - 1].bindTo('map', this.mapMarkers, 'points');
      } else {
        if (!this.lastMarker) {
          this.lastMarker = mark;

          this.lastMarker.bindTo('map', this.mapMarkers, 'emMk');
        } else {
          //update lastmarker

          this.lastMarker['infowindow'].setContent(popUp);
          this.lastMarker.setPosition(position);
        }

        google.maps.event.addListener(mark, 'click', () => {
          this.lastMarker['infowindow'].open(map, mark);
        });
      }
    } else {
      if (this.pointsMarkers[index]) {
        this.pointsMarkers[index]['infowindow'].setContent(popUp);
        this.pointsMarkers[index].setPosition(position);

        const idx = this.controls.findIndex(x => {
          return x.id === 'points';
        });

        const view = this.controls[idx].value ? map : null;

        this.pointsMarkers[index].setMap(view);
      } else {
        const idx = this.pointsMarkers.length;
        google.maps.event.addListener(mark, 'click', () => {
          this.pointsMarkers[idx]['infowindow'].open(map, mark);
        });

        this.pointsMarkers.push(mark);
        this.pointsMarkers[this.pointsMarkers.length - 1].bindTo('map', this.mapMarkers, 'points');
      }
    }

    return data;
  }

  //CIRCLE POSTS
  postCircleMapping() {
    try {
      const map = this.googlemap.data.getMap();
      this.mapMarkers.setValues({ members: map, units: map, gps: map, geofences: map });

      if (this.personMarker) {
        this.personMarker.unbindAll();
        this.personMarker.setMap(null);

        this.personMarker = null;
      }

      const idx = this.controls.findIndex(x => {
        return x.id === 'members';
      });
      if (idx < 0) {
        this.controls.push(
          {
            id: 'members',
            name: 'Usuarios',
            value: true,
            type: 'markers',
            layer: this.mapMarkers,
            markers: 'usersMarkers'
          },
          {
            id: 'units',
            name: 'Automóviles',
            value: true,
            type: 'markers',
            layer: this.mapMarkers,
            markers: 'unitsMarkers'
          },
          {
            id: 'gps',
            name: 'GPS',
            value: true,
            type: 'markers',
            layer: this.mapMarkers,
            markers: 'gpsMarkers'
          },
          {
            id: 'geofences',
            name: 'Geocercas',
            value: true,
            type: 'markers',
            layer: this.mapMarkers,
            markers: 'geofencesCircles'
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  undoCircleMapping() {
    try {
      this.setControl.removeLayer(this.membersPost);
      this.setControl.removeLayer(this.unitsPost);
      this.setControl.removeLayer(this.geofencesLayer);
    } catch (error) {}
  }

  membersMapping() {
    const map = this.googlemap.data.getMap();

    const idx = this.controls.findIndex(x => {
      return x.id === 'members';
    });

    for (let index = 0; index < this[this.controls[idx].markers].length; index++) {
      const element = this[this.controls[idx].markers][index];
      element.setMap(null);
    }

    this[this.controls[idx].markers] = [];
  }

  unitsMapping() {
    const map = this.googlemap.data.getMap();

    const idx = this.controls.findIndex(x => {
      return x.id === 'units';
    });

    for (let index = 0; index < this[this.controls[idx].markers].length; index++) {
      const element = this[this.controls[idx].markers][index];
      element.setMap(null);
    }

    this[this.controls[idx].markers] = [];
  }

  gpsMapping() {
    const map = this.googlemap.data.getMap();

    const idx = this.controls.findIndex(x => {
      return x.id === 'gps';
    });

    for (let index = 0; index < this[this.controls[idx].markers].length; index++) {
      const element = this[this.controls[idx].markers][index];
      element.setMap(null);
    }

    this[this.controls[idx].markers] = [];
  }

  circleMemberPost(memberData: any) {
    const iconSuffix: string =
      memberData.lastPost.emergency || memberData.lastPost.emergency === 1 ? '-sos.png' : '-reg.png';
    let iconUrl: string;
    let iconSize: number;
    iconUrl = './assets/icon/' + memberData.type + iconSuffix;
    iconSize = 68;

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const position = new google.maps.LatLng({
      lat: memberData.lastPost.coord.latitude,
      lng: memberData.lastPost.coord.longitude
    });

    const date = memberData.lastPost.published.toDate();

    const popup =
      '<h4 class="ion-text-center">' +
      memberData.username +
      '</h4>' +
      '<p>' +
      memberData.lastPost.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';

    const infowindow = new google.maps.InfoWindow({
      content: popup
    });

    const idx = this.usersMarkers.findIndex(x => {
      return x['key'] == memberData.key;
    });

    if (idx > -1) {
      this.usersMarkers[idx].setIcon(image);
      this.usersMarkers[idx].setPosition(position);
      this.usersMarkers[idx]['infowindow'].setContent(popup);
    } else {
      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      mark['username'] = memberData.username;
      mark['key'] = memberData.key;
      mark['memberData'] = memberData;
      mark['infowindow'] = infowindow;
      const index = this.usersMarkers.length;
      google.maps.event.addListener(mark, 'click', () => {
        this.usersMarkers[index]['infowindow'].open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'members');
      this.usersMarkers.push(mark);
    }
  }

  circleUnitPost(unitData: any) {
    const iconSuffix: string =
      unitData.lastPost.emergency || unitData.lastPost.emergency === 1 ? '-sos.png' : '-reg.png';
    let iconUrl: string;
    let iconSize: number;
    iconUrl = './assets/icon/' + unitData.type + iconSuffix;
    iconSize = 68;

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const position = new google.maps.LatLng({
      lat: unitData.lastPost.coord.latitude,
      lng: unitData.lastPost.coord.longitude
    });

    const date = unitData.lastPost.published.toDate();

    const popup =
      '<h4 class="ion-text-center">' +
      unitData.name_device +
      '</h4>' +
      '<p>' +
      (unitData.lastPost.direction ? unitData.lastPost.direction : '') +
      '</p>' +
      '<p>' +
      date +
      '</p>';

    const infowindow = new google.maps.InfoWindow({
      content: popup
    });

    const idx = this.unitsMarkers.findIndex(x => {
      return x['key'] == unitData.imei;
    });

    if (idx > -1) {
      this.unitsMarkers[idx].setIcon(image);
      this.unitsMarkers[idx].setPosition(position);
      this.unitsMarkers[idx]['infowindow'].setContent(popup);
    } else {
      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      mark['username'] = unitData.name_device;
      mark['key'] = unitData.imei;
      mark['unitData'] = unitData;
      mark['infowindow'] = infowindow;
      const index = this.unitsMarkers.length;
      google.maps.event.addListener(mark, 'click', () => {
        this.unitsMarkers[index]['infowindow'].open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'units');
      this.unitsMarkers.push(mark);
    }
  }

  circleGPSPost(gpsData: any) {
    const iconSuffix: string = gpsData.lastPost.emergency || gpsData.lastPost.emergency === 1 ? '-sos.png' : '-reg.png';
    let iconUrl: string;
    let iconSize: number;
    iconUrl = './assets/icon/gps' + iconSuffix;
    iconSize = 68;

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const position = new google.maps.LatLng({
      lat: gpsData.lastPost.coord.latitude,
      lng: gpsData.lastPost.coord.longitude
    });

    const date = gpsData.lastPost.published.toDate();

    const popup =
      '<h4 class="ion-text-center">' +
      gpsData.username +
      '</h4>' +
      '<p>' +
      (gpsData.lastPost.direction ? gpsData.lastPost.direction : '') +
      '</p>' +
      '<p>' +
      date +
      '</p>';

    const infowindow = new google.maps.InfoWindow({
      content: popup
    });

    const idx = this.gpsMarkers.findIndex(x => {
      return x['key'] == gpsData.key;
    });

    if (idx > -1) {
      this.gpsMarkers[idx].setIcon(image);
      this.gpsMarkers[idx].setPosition(position);
      this.gpsMarkers[idx]['infowindow'].setContent(popup);
    } else {
      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      mark['username'] = gpsData.username;
      mark['key'] = gpsData.key;
      mark['gpsData'] = gpsData;
      mark['infowindow'] = infowindow;
      const index = this.gpsMarkers.length;
      google.maps.event.addListener(mark, 'click', () => {
        this.gpsMarkers[index]['infowindow'].open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'gps');
      this.gpsMarkers.push(mark);
    }
  }

  circleSinglePost(data: any, index: number) {
    const date = new Date(data.published);
    const icon = L.icon({
      iconUrl: './assets/icon/' + data.type + '-old.png',
      iconAnchor: [25, 47],
      iconSize: [50, 50],
      popupAnchor: [0, -47]
    });
    const popUp =
      '<h4 class="ion-text-center">' +
      data.username +
      '</h4>' +
      '<p>' +
      data.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';

    let tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
      icon
    }).bindPopup(popUp);
    tmp['key'] = data.key;

    let add = false;

    if (index !== -1) {
      this.postGp.eachLayer((marker: L.Marker) => {
        if (marker['key'] == data.key) {
          const newLatLng = new L.LatLng(data.coord.latitude, data.coord.longitude);
          marker.setLatLng(newLatLng);
          marker.setPopupContent(popUp);
        }
      });
    } else {
      add = true;
    }

    if (add) {
      tmp.addTo(this.postGp);
    }
  }

  circleGeofence(geofence: any) {
    let desc = '';
    switch (geofence.transitionType) {
      case 1:
        desc = 'Entrada';
        break;
      case 2:
        desc = 'Salida';
        break;
      case 3:
        desc = 'Entrada y Salida';
        break;
      default:
        break;
    }
    const popup = '<h4 class="ion-text-center">' + geofence.name + '</h4>' + '<p>' + desc + '</p>';

    const infowindow = new google.maps.InfoWindow({
      content: popup
    });

    const idx = this.geofencesCircles.findIndex(x => {
      return x['key'] == geofence.key;
    });

    if (idx > -1) {
      this.geofencesCircles[idx].setCenter({ lat: geofence.center.latitude, lng: geofence.center.longitude });
      this.geofencesCircles[idx].setRadius(geofence.radius);
      this.geofencesCircles[idx]['infowindow'].setContent(popup);
    } else {
      const map = this.googlemap.data.getMap();

      const cityCircle = new google.maps.Circle({
        /* strokeColor: '#FF0000', */
        strokeOpacity: 0.8,
        strokeWeight: 2,
        /* fillColor: '#FF0000', */
        fillOpacity: 0.35,
        /* map, */
        center: { lat: geofence.center.latitude, lng: geofence.center.longitude },
        radius: geofence.radius,
        clickable: true
      });
      cityCircle['key'] = geofence.key;
      cityCircle['infowindow'] = infowindow;

      const index = this.geofencesCircles.length;
      google.maps.event.addListener(cityCircle, 'click', () => {
        const point = new google.maps.LatLng({
          lat: this.geofencesCircles[index]['center'].lat(),
          lng: this.geofencesCircles[index]['center'].lng()
        });
        this.geofencesCircles[index]['infowindow'].open(map);
        infowindow.open(map);
      });

      cityCircle.bindTo('map', this.mapMarkers, 'geofences');

      this.geofencesCircles.push(cityCircle);
    }
  }

  //PERSON POSTS
  postPersonMapping() {
    try {
      this.lastLocMarkers = [];

      if (this.personMarker) {
        this.personMarker.unbindAll();
        this.personMarker.setMap(null);

        this.personMarker = null;
      }

      const idx = this.controls.findIndex(x => {
        return x.id === 'last';
      });
      if (idx < 0) {
        const map = this.googlemap.data.getMap();
        this.mapMarkers.setValues({ last: null, single: map });

        this.controls.push({
          id: 'last',
          name: 'Últimas ubicaciones',
          value: false,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'lastLocMarkers'
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  personSinglePost(user: any) {
    const iconSuffix: string = user.lastPost ? (user.lastPost.emergency ? '-sos.png' : '-reg.png') : '-reg.png';
    let iconUrl: string;
    let iconSize: number;
    iconUrl = './assets/icon/user' + iconSuffix;
    iconSize = 68;

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const position = new google.maps.LatLng({
      lat: user.lastPost.coord.latitude ? user.lastPost.coord.latitude : user.lastPost.coord._latitude,
      lng: user.lastPost.coord.longitude ? user.lastPost.coord.longitude : user.lastPost.coord._longitude
    });

    let date = user.lastPost.published.toDate();
    let popup =
      '<h4 class="ion-text-center">' +
      (user.username ? user.username : user['name_device']) +
      '</h4>' +
      '<p>' +
      user.lastPost.body +
      '</p>' +
      '<p>' +
      (user.lastPost.direction ? user.lastPost.direction : '') +
      '</p>' +
      '<p>' +
      date +
      '</p>';

    if (this.personMarker) {
      this.personMarker.setIcon(image);
      this.personMarker.setPosition(position);
      this.personMarker['infowindow'].setContent(popup);
    } else {
      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      const infowindow = new google.maps.InfoWindow({
        content: popup
      });
      mark['infowindow'] = infowindow;

      google.maps.event.addListener(mark, 'click', () => {
        this.personMarker['infowindow'].open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'single');
      this.personMarker = mark;

      google.maps.event.trigger(mark, 'click');
    }
  }

  personPost(data: Emergency, index: number) {
    if (index < 0) {
      let iconUrl: string;
      let iconSize: number;
      iconUrl = './assets/icon/user' + '-old.png';
      iconSize = 30;

      let date = data.published.toDate();

      const map = this.googlemap.data.getMap();

      const image: google.maps.ReadonlyIcon = {
        url: iconUrl,
        scaledSize: new google.maps.Size(iconSize, iconSize),
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(0, 0)
      };

      const position = new google.maps.LatLng({
        lat: data.coord.latitude,
        lng: data.coord.longitude
      });

      let popup =
        '<h4 class="ion-text-center">' +
        data.username +
        '</h4>' +
        '<p>' +
        data.body +
        '</p>' +
        '<p>' +
        (data.direction ? data.direction : '') +
        '</p>' +
        '<p>' +
        date +
        '</p>';

      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      const infowindow = new google.maps.InfoWindow({
        content: popup
      });

      google.maps.event.addListener(mark, 'click', () => {
        infowindow.open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'last');
      this.lastLocMarkers.push(mark);
    }
  }

  gpsSinglePost(user: any) {
    const iconSuffix: string = user.lastPost ? (user.lastPost.emergency ? '-sos.png' : '-reg.png') : '-reg.png';
    let iconUrl: string;
    let iconSize: number;
    iconUrl = './assets/icon/' + user.lastPost.type + iconSuffix;
    iconSize = 68;

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const position = new google.maps.LatLng({
      lat: user.lastPost.coord.latitude,
      lng: user.lastPost.coord.longitude
    });

    let date = new Date(user.lastPost.published);
    let popup =
      '<h4 class="ion-text-center">' +
      user.owner +
      '</h4>' +
      '<p>' +
      user.lastPost.body +
      '</p>' +
      '<p>' +
      user.lastPost.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';

    if (this.personMarker) {
      this.personMarker.setIcon(image);
      this.personMarker.setPosition(position);
      this.personMarker['infowindow'].setContent(popup);
    } else {
      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      const infowindow = new google.maps.InfoWindow({
        content: popup
      });
      mark['infowindow'] = infowindow;

      google.maps.event.addListener(mark, 'click', () => {
        this.personMarker['infowindow'].open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'single');
      this.personMarker = mark;

      google.maps.event.trigger(mark, 'click');
    }
  }

  gpsPost(data: Emergency, index: number) {
    if (index < 0) {
      let iconUrl: string;
      let iconSize: number;
      iconUrl = './assets/icon/' + data.type + '-old.png';
      iconSize = 30;

      let date = new Date(data.published);

      const map = this.googlemap.data.getMap();

      const image: google.maps.ReadonlyIcon = {
        url: iconUrl,
        scaledSize: new google.maps.Size(iconSize, iconSize),
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(0, 0)
      };

      const position = new google.maps.LatLng({
        lat: data.coord.latitude,
        lng: data.coord.longitude
      });

      let popup =
        '<h4 class="ion-text-center">' +
        data.owner +
        '</h4>' +
        '<p>' +
        data.body +
        '</p>' +
        '<p>' +
        data.direction +
        '</p>' +
        '<p>' +
        date +
        '</p>';

      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      const infowindow = new google.maps.InfoWindow({
        content: popup
      });

      google.maps.event.addListener(mark, 'click', () => {
        infowindow.open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'last');
      this.lastLocMarkers.push(mark);
    }
  }

  //CAR POSTS
  postCarMapping() {
    try {
      const map = this.googlemap.data.getMap();
      this.mapMarkers.setValues({ last: null, single: map });

      this.controls.push({
        id: 'last',
        name: 'Últimas ubicaciones',
        value: false,
        type: 'markers',
        layer: this.mapMarkers,
        markers: 'lastLocMarkers'
      });
    } catch (error) {
      console.error(error);
    }
  }

  carSinglePost(car: any) {
    if (car.lastPost) {
      const iconSuffix: string = car.lastPost.emergency || car.lastPost.emergency === 1 ? '-sos.png' : '-reg.png';
      let iconUrl: string;
      let iconSize: number;
      iconUrl = './assets/icon/car' + iconSuffix;
      iconSize = 68;

      const map = this.googlemap.data.getMap();

      const image: google.maps.ReadonlyIcon = {
        url: iconUrl,
        scaledSize: new google.maps.Size(iconSize, iconSize),
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(0, 0)
      };

      const position = new google.maps.LatLng({
        lat: car.lastPost.coord.latitude,
        lng: car.lastPost.coord.longitude
      });

      const date = car.lastPost.published.toDate();

      const popup =
        '<h4 class="ion-text-center">' +
        car.name_device +
        '</h4>' +
        '<p>' +
        (car.lastPost.direction ? car.lastPost.direction : '') +
        '</p>' +
        '<p>' +
        date +
        '</p>';

      if (this.carMarker) {
        this.carMarker.setIcon(image);
        this.carMarker.setPosition(position);
        this.carMarker['infowindow'].setContent(popup);
      } else {
        const mark = new google.maps.Marker({
          position: position,
          icon: image
        });
        const infowindow = new google.maps.InfoWindow({
          content: popup
        });
        mark['infowindow'] = infowindow;

        google.maps.event.addListener(mark, 'click', () => {
          this.carMarker['infowindow'].open(map, mark);
        });

        mark.bindTo('map', this.mapMarkers, 'single');
        this.carMarker = mark;

        google.maps.event.trigger(mark, 'click');
      }
    }
  }

  carPost(data: Emergency, index: number) {
    if (index < 0) {
      let iconUrl: string;
      let iconSize: number;
      iconUrl = './assets/icon/car' + '-old.png';
      iconSize = 30;

      let username;
      /*       switch (data.type) {
        case 'car': */
      username = data.cid + ' - ' + data.name_device;
      /*          break;

        default:
          username = data.username ? data.username : data.owner;
          break;
      } */

      let date = data.published.toDate();

      const map = this.googlemap.data.getMap();

      const image: google.maps.ReadonlyIcon = {
        url: iconUrl,
        scaledSize: new google.maps.Size(iconSize, iconSize),
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(0, 0)
      };

      const position = new google.maps.LatLng({
        lat: data.coord.latitude,
        lng: data.coord.longitude
      });

      let popup =
        '<h4 class="ion-text-center">' +
        username +
        '</h4>' +
        '<p>' +
        data.body +
        '</p>' +
        '<p>' +
        data.direction +
        '</p>' +
        '<p>' +
        date +
        '</p>';

      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      const infowindow = new google.maps.InfoWindow({
        content: popup
      });

      google.maps.event.addListener(mark, 'click', () => {
        infowindow.open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'last');
      this.lastLocMarkers.push(mark);
    }
  }

  //GENERAL POSTS
  postGeneralMapping() {
    try {
      const map = this.googlemap.data.getMap();
      this.mapMarkers.setValues({ members: map, units: map, geofences: map });

      this.controls.push(
        {
          id: 'members',
          name: 'Usuarios',
          value: true,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'usersMarkers'
        },
        {
          id: 'units',
          name: 'Automóviles',
          value: true,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'unitsMarkers'
        },
        {
          id: 'geofences',
          name: 'Geocercas',
          value: true,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'geofencesCircles'
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  lastGeneralPost(key: string, data: Emergency, index: number) {
    const iconUrl: string = './assets/icon/person-reg.png';
    //const iconUrl: string = './assets/icon/Avatar_perro_rojo_512x512px (6).png';
    const iconSize: number = 50;

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const position = new google.maps.LatLng({
      lat: data.coord.latitude,
      lng: data.coord.longitude
    });

    const date = new Date(data.published.toDate());
    const person = (data.username ? data.username : '') + ' ' + (data.owner ? data.owner : '');
    const popup =
      '<h4 class="ion-text-center">' + person + '</h4>' + '<p>' + data.direction + '</p>' + '<p>' + date + '</p>';

    const infowindow = new google.maps.InfoWindow({
      content: popup
    });

    const idx = this.usersMarkers.findIndex(x => {
      return x['key'] == key;
    });

    if (idx > -1) {
      //this.usersMarkers[idx].setIcon(image);
      this.usersMarkers[idx].setPosition(position);
      this.usersMarkers[idx]['infowindow'].setContent(popup);
    } else {
      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      mark['username'] = data.username;
      mark['key'] = key;
      mark['userData'] = data;
      mark['infowindow'] = infowindow;
      const index = this.usersMarkers.length;
      google.maps.event.addListener(mark, 'click', () => {
        this.usersMarkers[index]['infowindow'].open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'members');
      this.usersMarkers.push(mark);
    }
  }

  lastGeneralCarPost(key: string, data: Emergency, index: number) {
    const iconUrl: string = './assets/icon/car-reg.png';
    const iconSize: number = 50;

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const position = new google.maps.LatLng({
      lat: data.coord.latitude,
      lng: data.coord.longitude
    });

    const date = new Date(data.published.toDate());
    const popup =
      `<h4 class="ion-text-center">${data.name_device}</h4>` +
      `<ion-grid>` +
      `<ion-row><ion-col size="3">Dirección </ion-col> <ion-col size="9">${data.direction}</ion-col><ion-row>` +
      `<ion-row><ion-col size="3">Posición </ion-col> <ion-col size="9">${data.coord.latitude}, ${data.coord.longitude}</ion-col><ion-row>` +
      `<ion-row><ion-col size="3">Velocidad </ion-col> <ion-col size="9">${data['vel']} kph</ion-col><ion-row>` +
      `<ion-row><ion-col size="3">Odómetro </ion-col> <ion-col size="9">${data['odometro_kms']} km</ion-col><ion-row>` +
      `<ion-row><ion-col size="3">Hora </ion-col> <ion-col size="9">${date}</ion-col><ion-row>` +
      `<ion-grid>`;
    const infowindow = new google.maps.InfoWindow({
      content: popup
    });

    const idx = this.unitsMarkers.findIndex(x => {
      return x['key'] == key;
    });

    if (idx > -1) {
      //this.unitsMarkers[idx].setIcon(image);
      this.unitsMarkers[idx].setPosition(position);
      this.unitsMarkers[idx]['infowindow'].setContent(popup);
    } else {
      const mark = new google.maps.Marker({
        position: position,
        icon: image
      });
      mark['username'] = data.name_device;
      mark['key'] = key;
      mark['unitData'] = data;
      mark['infowindow'] = infowindow;
      const index = this.unitsMarkers.length;
      google.maps.event.addListener(mark, 'click', () => {
        this.unitsMarkers[index]['infowindow'].open(map, mark);
      });

      mark.bindTo('map', this.mapMarkers, 'units');
      this.unitsMarkers.push(mark);
    }
  }

  setViewGeneralPost(key: string) {
    this.postGp.eachLayer((marker: L.Marker) => {
      if (marker['key'] == key) {
        marker.fireEvent('click');
        this.setView({ latitude: marker.getLatLng().lat, longitude: marker.getLatLng().lng }, 15);
      }
    });
  }

  setViewGeneralCarPost(key: string) {
    this.postCarGp.eachLayer((marker: L.Marker) => {
      if (marker['key'] == key) {
        marker.fireEvent('click');
        this.setView({ latitude: marker.getLatLng().lat, longitude: marker.getLatLng().lng }, 15);
      }
    });
  }

  //FOLLOW ME POSTS

  postFollowMapping() {
    try {
      this.lineArr = [];
      const map = this.googlemap.data.getMap();
      this.mapMarkers.setValues({ last: null, points: null, line: true, emMk: map });
      this.controls.push(
        {
          id: 'last',
          name: 'Últimas ubicaciones',
          value: false,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'lastLocMarkers'
        },
        {
          id: 'points',
          name: 'Live Tracking (puntos)',
          value: false,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'pointsMarkers'
        },
        {
          id: 'line',
          name: 'Live Tracking (línea)',
          value: true,
          type: 'line',
          layer: this.mapMarkers,
          line: 'pointsLine'
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  followMapping() {
    try {
      const map = this.googlemap.data.getMap();
      this.mapMarkers.setValues({ origin: map, destination: map });
    } catch (error) {
      console.error(error);
    }
  }

  async postFollowUserMapping(posts: Array<any>) {
    this.postGp.clearLayers();
    await posts.map(data => {
      let username;
      switch (data.type) {
        case 'car':
          username = data.cid + ' - ' + data.name_device;
          break;

        default:
          username = data.username ? data.username : data.owner;
          break;
      }

      const icon = L.icon({
        iconUrl: './assets/icon/' + data.type + '-old.png',
        iconAnchor: [25, 5],
        iconSize: [50, 50],
        popupAnchor: [0, -5]
      });
      const popUp =
        '<h4 class="ion-text-center">' +
        username +
        '</h4>' +
        '<p>' +
        data.direction +
        '</p>' +
        '<p>' +
        this.datePipe.transform(data.published, 'medium') +
        '</p>';
      const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
        icon
      })
        .bindPopup(popUp)
        .addTo(this.postGp);
    });
  }

  postFollowPoints(follow: Follow, type: string) {
    let iconUrl: string;
    let iconSize: number;
    let popup = '';
    let coords = {
      lat: 0,
      lng: 0
    };

    switch (type) {
      case 'origin':
        iconUrl = './assets/icon/person-reg.png';
        iconSize = 50;
        popup =
          '<ion-text color="primary"><h3 class="ion-text-center">Origen Acompáñame</h3></ion-text>' +
          '<h5 class="ion-text-center">' +
          follow.username +
          '</h5>' +
          '<p>' +
          follow.origin.direction +
          '</p>';
        coords.lat = follow.origin.coord.latitude;
        coords.lng = follow.origin.coord.longitude;
        break;
      case 'destination':
        iconUrl = './assets/icon/flag.png';
        iconSize = 32;
        popup =
          '<ion-text color="primary"><h3 class="ion-text-center">Destino Acompáñame</h3></ion-text>' +
          '<h5 class="ion-text-center">' +
          follow.username +
          '</h5>' +
          '<p>' +
          follow.destination.direction +
          '</p>' +
          '<p>' +
          this.datePipe.transform(follow.published.toDate(), 'medium') +
          '</p>';

        coords.lat = follow.destination.coord.latitude;
        coords.lng = follow.destination.coord.longitude;
        break;

      default:
        break;
    }

    const map = this.googlemap.data.getMap();

    const image: google.maps.ReadonlyIcon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(iconSize, iconSize),
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(0, 0)
    };

    const mark = new google.maps.Marker({
      position: { lat: coords.lat, lng: coords.lng },
      icon: image
    });

    const infowindow = new google.maps.InfoWindow({
      content: popup
    });

    mark.addListener('click', () => {
      infowindow.open(map, mark);
    });

    mark.bindTo('map', this.mapMarkers, 'emMk');

    google.maps.event.trigger(mark, 'click');
  }

  removeFollow() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.setControl.removeLayer(this.lastLocGp);
    this.setControl.removeLayer(this.lastLocLn);
    this.setControl.removeLayer(this.lastLocMk);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.postSng.clearLayers();
    this.postGp.clearLayers();
    this.map.removeLayer(this.postSng);
    this.map.removeLayer(this.postGp);
    this.map.removeLayer(this.lastLocGp);
    this.map.removeLayer(this.lastLocLn);
    this.map.removeLayer(this.lastLocMk);
  }

  //TRACKING POSTS
  postTrackingMapping() {
    try {
      const map = this.googlemap.data.getMap();
      this.mapMarkers.setValues({ last: null, points: null, line: true, single: map });
      this.controls.push(
        {
          id: 'last',
          name: 'Últimas ubicaciones',
          value: false,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'lastLocMarkers'
        },
        {
          id: 'points',
          name: 'Live Tracking (puntos)',
          value: false,
          type: 'markers',
          layer: this.mapMarkers,
          markers: 'pointsMarkers'
        },
        {
          id: 'line',
          name: 'Live Tracking (línea)',
          value: true,
          type: 'line',
          layer: this.mapMarkers,
          line: 'pointsLine'
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  async postTrackingUserMapping(posts: Array<any>) {
    this.postGp.clearLayers();
    await posts.map(data => {
      let username;
      switch (data.type) {
        case 'car':
          username = data.cid + ' - ' + data.name_device;
          break;

        default:
          username = data.username ? data.username : data.owner;
          break;
      }

      const icon = L.icon({
        iconUrl: './assets/icon/' + data.type + '-old.png',
        iconAnchor: [25, 5],
        iconSize: [50, 50],
        popupAnchor: [0, -5]
      });
      const popUp =
        '<h4 class="ion-text-center">' +
        username +
        '</h4>' +
        '<p>' +
        data.direction +
        '</p>' +
        '<p>' +
        this.datePipe.transform(data.published, 'medium') +
        '</p>';
      const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
        icon
      })
        .bindPopup(popUp)
        .addTo(this.postGp);
    });
  }

  trackingOrigin(follow: Follow) {
    const iconorigin = L.icon({
      iconUrl: './assets/icon/person-reg.png',
      iconAnchor: [34, 5],
      iconSize: [68, 68],
      popupAnchor: [0, -5]
    });

    const originPop =
      '<ion-text color="primary"><h3 class="ion-text-center">Origen Acompáñame</h3></ion-text>' +
      '<h5 class="ion-text-center">' +
      follow.username +
      '</h5>' +
      '<p>' +
      follow.origin.direction +
      '</p>';
    const marker: L.Marker = L.marker([follow.origin.coord.latitude, follow.origin.coord.longitude], {
      icon: iconorigin
    }).bindPopup(originPop);
    marker.addTo(this.map);
  }

  trackingDestination(follow: Follow) {
    const icon = L.icon({
      iconUrl: './assets/icon/flag.png',
      iconSize: [50, 50]
    });

    const destinationPop =
      '<ion-text color="primary"><h3 class="ion-text-center">Destino Acompáñame</h3></ion-text>' +
      '<h5 class="ion-text-center">' +
      follow.username +
      '</h5>' +
      '<p>' +
      follow.origin.direction +
      '</p>' +
      '<p>' +
      this.datePipe.transform(follow.published.toDate(), 'medium') +
      '</p>';
    const tmp = new L.Marker([follow.destination.coord.latitude, follow.destination.coord.longitude], {
      icon
    }).bindPopup(destinationPop);
    tmp.addTo(this.map);
  }

  setViewTrackingPost(key: string, click?: boolean) {
    /* this.lastLocMk.eachLayer((marker: L.Marker) => {
      if (marker['key'] == key) {
        if (click || click === undefined) {
          marker.fireEvent('click');
        }
        this.setView({ latitude: marker.getLatLng().lat, longitude: marker.getLatLng().lng }, 15);
      }
    });
    this.lastLocGp.eachLayer((marker: L.Marker) => {
      if (marker['key'] == key) {
        marker.fireEvent('click');
        this.setView({ latitude: marker.getLatLng().lat, longitude: marker.getLatLng().lng }, 15);
      }
    }); */
  }

  removeTracking() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.setControl.removeLayer(this.lastLocGp);
    this.setControl.removeLayer(this.lastLocLn);
    this.setControl.removeLayer(this.lastLocMk);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.postSng.clearLayers();
    this.postGp.clearLayers();
    this.map.removeLayer(this.postSng);
    this.map.removeLayer(this.postGp);
    this.map.removeLayer(this.lastLocGp);
    this.map.removeLayer(this.lastLocLn);
    this.map.removeLayer(this.lastLocMk);
  }

  //HELPERS
  setView(coord: Coords, zoom: number) {
    const map = this.googlemap.data.getMap();
    const point = new google.maps.LatLng(coord.latitude, coord.longitude);
    map.panTo(point);
    map.setZoom(zoom);
  }

  //MATHS DATA
  getDistanceFromLatLonInKm(coord1: Coords, coord2: Coords) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(coord1.latitude - coord2.latitude); // deg2rad below
    const dLon = this.deg2rad(coord1.longitude - coord2.longitude);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(coord1.latitude)) *
        Math.cos(this.deg2rad(coord2.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  async getAddress(coords: Coords, nameOnly?: boolean) {
    try {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const params =
        'https://nominatim.openstreetmap.org/reverse?format=jsonv2&' +
        'lat=' +
        coords.latitude +
        '&lon=' +
        coords.longitude;
      const req = await this.http.get(params, httpOptions).toPromise();
      return nameOnly ? (req['name'] ? req['name'] : req['address'].road) : req['display_name'];
    } catch (error) {
      return '';
    }
  }

  corporateZones(corporate: string) {
    if (this.map) {
      this.corporateGeoLayer.addTo(this.map);
    } else {
      const map = this.googlemap.data.getMap();

      this.corpGeofencesLayer = new google.maps.Data({ map: null });
      this.controls.push({
        id: 'zones',
        name: 'Zonas de riesgo',
        value: false,
        type: 'layer',
        layer: this.corpGeofencesLayer
      });
    }

    if (this.dataService.corporategeo.length > 0) {
      this.corporategeo = this.dataService.corporategeo;
      this.evaluategeofencesagm(this.corporategeo);
    } else {
      this.dataService.getCorporateZones(corporate);
    }

    this.corZonesSub = this.dataService.sendGeofences$.subscribe(() => {
      if (this.drawGeofences) {
        this.corporategeo = this.dataService.corporategeo;
        this.evaluategeofencesagm(this.corporategeo);
      }
    });
  }

  evaluategeofencesagm(geofences: string | any[]) {
    for (let index = 0; index < geofences.length; index++) {
      const element = geofences[index];
      this.evaluatefenceagm(element);
    }
  }

  evaluatefenceagm(element: any) {
    const id = element.id ? element.id : element.key;
    const name = element.name ? element.name : 'Sin título';
    const origin = element.origin ? element.origin : 'war room';
    const risk_level_id = element.risk_level_id ? element.risk_level_id : null;
    const serverId = element.serverId ? element.serverId : null;
    let figure: google.maps.Circle | google.maps.Polygon; /* L.Layer | L.Circle | L.Polygon; */

    const color = element.color ? element.color : '#808080';

    switch (element.shape.toLowerCase()) {
      case 'circle':
        figure = new google.maps.Circle({
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.35,
          /* map, */
          center: { lat: element.center.lat, lng: element.center.lng },
          radius: element.radius,
          clickable: true
        });
        figure['key'] = id;
        figure.bindTo('map', this.corpGeofencesLayer);

        break;
      case 'polygon':
        const evaluationpoints = element.points ? element.points : element.latlngs;
        let points = [];
        for (let index = 0; index < evaluationpoints.length; index++) {
          const element1 = evaluationpoints[index];
          const obj = {
            lat: element1.latitude ? element1.latitude : element1.lat,
            lng: element1.longitude ? element1.longitude : element1.lng
          };
          points.push(obj);
        }

        figure = new google.maps.Polygon({
          paths: points,
          strokeColor: color, //color,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: color, //color,
          fillOpacity: 0.3 //opacity > 1 ? 1 : opacity
        });

        figure.bindTo('map', this.corpGeofencesLayer);

        break;

      default:
        break;
    }

    if (figure) {
      figure['key'] = id;
      figure['name'] = name;
      figure['origin'] = origin;
      figure['risk_level_id'] = risk_level_id;
      figure['serverId'] = serverId;
    }
  }

  evaluategeofences(geofences: string | any[]) {
    for (let index = 0; index < geofences.length; index++) {
      const element = geofences[index];
      this.evaluatefence(element);
    }
  }

  evaluatefence(element: any) {
    const id = element.id ? element.id : element.key;
    const name = element.name ? element.name : 'Sin título';
    const origin = element.origin ? element.origin : 'war room';
    const risk_level_id = element.risk_level_id ? element.risk_level_id : null;
    const serverId = element.serverId ? element.serverId : null;
    let figure: L.Layer | L.Circle | L.Polygon;

    this.corporateGeoLayer.eachLayer(layer => {
      if (layer['key']) {
        if (layer['key'] == id) {
          this.corporateGeoLayer.removeLayer(layer);
        }
      }
    });

    const color = element.color ? element.color : '#808080';

    switch (element.shape.toLowerCase()) {
      case 'circle':
        const point = {
          lat: element.center.latitude ? element.center.latitude : element.center.lat,
          lng: element.center.longitude ? element.center.longitude : element.center.lng
        };
        const radius = element.radius;
        figure = new L.Circle(point, radius);
        break;
      case 'polygon':
        const evaluationpoints = element.points ? element.points : element.latlngs;
        let points = [];
        for (let index = 0; index < evaluationpoints.length; index++) {
          const element1 = evaluationpoints[index];
          const obj = {
            lat: element1.latitude ? element1.latitude : element1.lat,
            lng: element1.longitude ? element1.longitude : element1.lng
          };
          points.push(obj);
        }

        figure = new L.Polygon(points);
        break;

      default:
        break;
    }

    figure['key'] = id;
    figure['name'] = name;
    figure['origin'] = origin;
    figure['setStyle']({ color: color });
    figure['risk_level_id'] = risk_level_id;
    figure['serverId'] = serverId;

    this.addgeofence(figure);
  }

  addgeofence(figure: L.Layer | L.Polygon | L.Circle) {
    figure.addTo(this.corporateGeoLayer);
    this.setPopUp(figure);
    this.getList();

    figure.on('pm:edit', async e => {
      const layer = await this.checkType(e.layer);
      layer.key = e.layer['key'] ? e.layer['key'] : e.layer['_leaflet_id'];
      layer['shape'] = e.shape.toLocaleLowerCase();

      this.evaluatefence(layer);
    });

    figure.on('pm:remove', e => {
      this.getList();
    });
  }

  setPopUp(e: L.Polygon | L.Circle | L.Layer) {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;
    const rootPath = s[0].path;
    if (rootPath == 'geofences') {
      /* Crear popup infowindow */

      //Usar this_ como variable local
      let this_ = this;

      //Div padre en InfoWindow
      var parentDiv = document.createElement('div');
      parentDiv.className = 'parentDiv';

      /*     //Información del post
    var infoDiv = document.createElement('div');
    let infoData = '';
    const title = e['name'] ? `<b> ${e['name']} </b> <br>` : `<b> Sin título </b> <br>`;
    infoData = title;
    infoDiv.innerHTML = infoData;

    parentDiv.appendChild(infoDiv); */

      //Div padre de botones
      var parentButtons = document.createElement('div');
      parentButtons.className = 'parentButtons';

      const name = document.createElement('input');
      name.id = 'geofence-id';
      name.type = 'text';
      name.value = e['name'];

      const deleteBtn = document.createElement('div');
      deleteBtn.innerHTML = `<ion-button expand="block" color="danger" fill="solid" style="width:100%>
          <ion-icon slot="end" name="trash-outline"></ion-icon>
          <ion-label>ELIMINAR</ion-label>
        </ion-button>`;
      deleteBtn.addEventListener('click', function() {
        /* Abrir street view */
        this_.delete(e);
      });

      const saveBtn = document.createElement('div');
      saveBtn.innerHTML = `<ion-button expand="block" color="primary" fill="solid" style="width:100%
          (click)="save()"
        >
          <ion-icon slot="end" name="save-outline"></ion-icon>
          <ion-label>GUARDAR</ion-label>
        </ion-button>`;
      saveBtn.style.width = '%100';
      saveBtn.addEventListener('click', function() {
        /* Abrir street view */
        this_.saveData(e);
      });

      parentButtons.appendChild(name);
      parentButtons.appendChild(saveBtn);
      parentButtons.appendChild(deleteBtn);
      parentDiv.appendChild(parentButtons);

      const popUp = parentDiv;
      const p = new L.Popup({ autoClose: true, closeOnClick: true }).setContent(popUp);
      e.bindPopup(p);
    }
  }

  saveData(layer: L.Circle | L.Polygon | L.Layer) {
    const geofenceId = document.getElementById('geofence-id');
    const name = geofenceId['value'] ? geofenceId['value'] : 'Sin título';

    const index = this.tmpcorporategeo.findIndex(x => {
      return x.key == layer['key'];
    });

    this.tmpcorporategeo[index].name = name;
    layer['name'] = name;
    layer.closePopup();
    layer.openPopup();
  }

  changeRisk(ev: any, key: string | number) {
    this.map.eachLayer(layer => {
      if (layer['key'] == key) {
        const idx = this.zoneLevels.findIndex(x => {
          return x.id == ev.detail.value;
        });

        layer['risk_level_id'] = ev.detail.value;
        layer['setStyle']({ color: this.zoneLevels[idx].color });

        const index = this.tmpcorporategeo.findIndex(x => {
          return x.key == layer['key'];
        });

        this.tmpcorporategeo[index].risk_level_id = ev.detail.value;
        this.tmpcorporategeo[index].color = this.zoneLevels[idx].color;
      }
    });
  }

  delete(e: L.Layer) {
    this.corporateGeoLayer.removeLayer(e);
    this.getList();
  }

  async checkType(layer: any) {
    const shape = layer['_mRadius'] ? 'circle' : 'polygon';
    const key = layer['key'] ? layer['key'] : null;
    const origin = layer['origin'] ? layer['origin'] : 'war room';
    const color = layer['options'].color ? layer['options'].color : '#808080';
    const risk_level_id = layer['risk_level_id'] ? layer['risk_level_id'] : 'none';

    let zone = {
      shape: shape,
      key: key,
      name: '',
      origin,
      color: color,
      risk_level_id: risk_level_id
    };

    switch (shape) {
      case 'circle':
        zone['radius'] = layer['_mRadius'];
        zone['center'] = layer['_latlng'];
        break;
      case 'polygon':
        let latlngs = [];
        for (let index = 0; index < layer['_latlngs'][0].length; index++) {
          const element = layer['_latlngs'][0][index];
          latlngs.push(element);
        }
        latlngs.push(layer['_latlngs'][0][0]);
        zone['points'] = latlngs;
        zone['center'] = layer['_bounds'].getCenter();
        break;

      default:
        break;
    }
    const name = layer['name']
      ? layer['name']
      : await this.getAddress({ latitude: zone['center'].lat, longitude: zone['center'].lng }, true);
    zone.name = name;

    return zone;
  }

  async getList() {
    let zones: { shape: string; key: any }[] = [];
    this.corporateGeoLayer.eachLayer(async (layer: L.Circle | L.Polygon | L.Layer) => {
      const zone = await this.checkType(layer);
      zones.push(zone);
    });
    this.tmpcorporategeo = zones;
  }

  subscribetoevents() {
    this.map.on('pm:create', async ({ layer }) => {
      layer['setStyle']({ color: '#808080' });
      layer['key'] = layer['_leaflet_id'];
      layer['origin'] = 'war room';
      layer['color'] = '#808080';
      layer['risk_level_id'] = 'none';
      const converted = await this.checkType(layer);
      layer['name'] = converted.name;
      layer['shape'] = converted.name;

      this.getList();
      this.setPopUp(layer);
      layer.fireEvent('click');

      layer.on('pm:edit', async e => {
        const lay = await this.checkType(e.layer);
        lay['shape'] = e.shape != 'Rectangle' ? e.shape : 'polygon';
        this.evaluatefence(lay);
      });
    });

    this.map.on('pm:remove', ({ layer }) => {
      this.getList();
    });
  }

  setViewGeofence(key: string) {
    this.corporateGeoLayer.eachLayer((layer: L.Circle | L.Polygon) => {
      if (layer['key'] == key) {
        layer.fireEvent('click');
        const coord = layer['_latlng']
          ? this.map.flyToBounds(layer.getBounds())
          : this.map.flyToBounds(layer['_bounds']);
      }
    });
  }

  openWindow(url: string) {
    window.open(url, 'Google Maps');
  }

  switchLayer(data: any, idx: number, ev: any) {
    const map = this.googlemap.data.getMap();
    const view = this.controls[idx].value ? map : null;

    switch (data.type) {
      case 'markers':
        for (let index = 0; index < this[this.controls[idx].markers].length; index++) {
          const element = this[this.controls[idx].markers][index];
          element.setMap(view);
        }
        break;
      case 'layer':
        if (data.id == 'traffic') {
          this.dataService.traffic = data.value;
        }
        data.layer.setMap(view);
        break;
      case 'line':
        this[this.controls[idx].line].setMap(view);
        break;
      default:
        break;
    }
  }
}

import { tileLayer, Control, Polygon, layerGroup, FeatureGroup, LayerGroup } from 'leaflet';

export const basicMap = tileLayer(
  'https://api.maptiler.com/maps/737cb936-d884-40e3-b85f-f50fcc6574b0/256/{z}/{x}/{y}@2x.png?key=cTY7EGO9Q23js5E3TXOo',
  {
    attribution: 'Tecnotrust Maps'
  }
);
export const hybridMap = tileLayer(
  'https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}@2x.jpg?key=cTY7EGO9Q23js5E3TXOo',
  {
    attribution: 'Tecnotrust Maps'
  }
);
export const darkMatter = tileLayer(
  'https://api.maptiler.com/maps/77725521-4085-45dd-aa03-cecf4488d245/256/{z}/{x}/{y}@2x.png?key=cTY7EGO9Q23js5E3TXOo',
  {
    attribution: 'Tecnotrust Maps'
  }
);
export const setControl = new Control.Layers({
  Mapa: basicMap,
  Híbrido: hybridMap,
  Night: darkMatter
});

export const mapOptions: L.MapOptions = {
  center: [19.4284706, -99.1276627],
  zoom: 12,
  layers: [basicMap]
};

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Credentials, CredentialsService } from './credentials.service';
import { auth } from 'firebase/app';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@env/environment';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private credentialsService: CredentialsService,
    private afd: AngularFireDatabase,
    private afs: AngularFirestore,
    private alertController: AlertController
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */

  async loginGoogle(): Promise<any> {
    const provider = new auth.GoogleAuthProvider();
    return this.afAuth.auth.signInWithPopup(provider);
  }

  async loginEmail(email: string, password: string): Promise<any> {
    const provider = new auth.GoogleAuthProvider();
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.afAuth.auth.signOut();
    this.credentialsService.setCredentials();
    return of(true);
  }

  getTokenid(condition: boolean) {
    return this.afAuth.auth.currentUser.getIdToken(condition);
  }

  subscribeAuth(): void {
    this.afAuth.authState.subscribe((response: firebase.User) => {
      if (response) {
        this.afs
          .doc('wrUsers/' + response.uid)
          .valueChanges()
          .subscribe((info: any) => {
            const names = response.email.split('@');
            const name = names[0];
            const domain = names[1].replace(/\./g, '');

            if (info) {
              const cred = {
                username: name,
                token: response.uid,
                domain: domain,
                role: info.role,
                group: info.group ? info.group : environment.GROUP != '' ? environment.GROUP : ''
              };
              this.credentialsService.setCredentials(cred, true);
              this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], {
                replaceUrl: true
              });
            } else {
              this.presentAlert();
            }
          });
      } else {
        this.logout();
      }
    });
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'No autorizado',
      message: 'No se encuentra autorizado para entrar, solicite un acceso.',
      buttons: ['OK']
    });
    this.logout();
    await alert.present();
  }
}

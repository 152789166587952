import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { LoaderComponent } from './loader/loader.component';
import { cleanPostsPipe } from '@app/circle/cleanposts.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GoogleMapsModule } from '@angular/google-maps';
import { DatasheetComponent } from './datasheet/datasheet.component';
import { FollowsheetComponent } from './followsheet/followsheet.component';
import { UserComponent } from './user/user.component';
import { CirclesearchComponent } from './circlesearch/circlesearch.component';
import { UsersearchComponent } from './usersearch/usersearch.component';
import { MapComponent } from './map/map.component';
import { AgmComponent } from './agm/agm.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { environment } from '@env/environment';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebase.apiKey,
      libraries: ['places']
    }),
    AngularEditorModule,
    GoogleMapsModule
  ],
  declarations: [
    LoaderComponent,
    DatasheetComponent,
    FollowsheetComponent,
    MapComponent,
    UserComponent,
    AgmComponent,
    CirclesearchComponent,
    UsersearchComponent
  ],
  exports: [
    LoaderComponent,
    DatasheetComponent,
    MapComponent,
    UserComponent,
    AgmComponent,
    CirclesearchComponent,
    UsersearchComponent
  ],
  providers: [DatePipe, GoogleMapsAPIWrapper]
})
export class SharedModule {}
